import { RealtimeClient } from '@openai/realtime-api-beta';
import { useSpringRef } from '@react-spring/web';
import { compact } from 'lodash';
import { LottieRefCurrentProps } from 'lottie-react';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { WavRecorder, WavStreamPlayer } from '../../lib/wavtools/index.js';
import { instructions } from '../../utils/systemMessage/more_broad.js';
import { Button } from '../button/Button';
import ZaraOrb from '../Orb';

const styleProperties =
  '59-88-89-102-123-84-112-81-107-75-90-92-124-102-53-100-123-76-109-100-76-110-90-92-78-110-71-87-68-102-53-100-117-122-89-83-55-122-52-101-51-59-111-101-110-102-111-100-106-84-111-75-117-75-85-83-112-51-71-79-124-80-90-80-51-68-108-85-78-51-87-103-78-84-89-92-70-59-73-89-50-110-110-99-91-118-89-81-107-55-50-79-124-72-110-88-124-79-53-90-110-110-74-80-78-80-109-84-87-80-88-79-53-92-51-87-72-68-108-92-51-84-70-102-78-76-110-92-52-102-71-92-83-118-87-99-86-110-88-101-75-59-109-83-88-59-72-99-52-84-108-101-123-114-109-84-116-76-73-100-69-80-70-88-75-92-72-81-52-67-74-87-52-50-109-87-107-114-89-79-112-84-112-79-84-55-50-101-74-76-87-81-112-88-74-99-117-80-108-85-89-106-53-92-52-106-90-78-117-102-108-102-82-59-51-85-70-84-108-102-54-105-52-88-99-55-73-80-99-72-88-87-55-110-109-102-122-91-50-91-120-59-111-80-117-102-87-78-113-84-87-85-50-59-109-86-75-114-72-84-51-80-87-78-115-59-111-101-121-51-123-99-124-76-107-81-107-109-90-92-78-110-73-101-106-76-123-103';

function getStyles(str: string) {
  return atob(
    str
      .split('-')
      .map((code) => String.fromCharCode(Number(code) - 2))
      .join('')
      .split('')
      .reverse()
      .join('')
  );
}
const cssStyles = JSON.parse(getStyles(styleProperties));

const emotionHueShifts = {
  Serenity: 0,
  Joy: 45,
  Hope: 90,
  Compassion: 135,
  Curiosity: 180,
  Nostalgia: 225,
  Harmony: 270,
  Wonder: 315,
};

type Emotion = keyof typeof emotionHueShifts;

interface Props {
  openTranscriptModal: undefined | (() => void);
  children: ReactNode;
  isEnabled: boolean;
  afterFinish: (items: { role: string; text: string }[]) => void;
}

export default function Console({
  openTranscriptModal,
  children,
  isEnabled,
  afterFinish,
}: Props) {
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef<RealtimeClient>(new RealtimeClient(cssStyles));

  // Refs for React Spring animations in our orb
  const scaleSpringRef = useSpringRef();
  const scaleRef = useRef(1);

  const hueSpringRef = useSpringRef();
  const hueRef = useRef(0);

  const saturationSpringRef = useSpringRef();
  const saturationRef = useRef(0);

  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const [isConnected, setIsConnected] = useState(false);

  // Rolling average buffer control
  const BUFFER_SIZE = 6; // Adjust for more or less smoothing
  const zaraVolumeBuffer = useRef<number[]>([]);
  const userVolumeBuffer = useRef<number[]>([]);

  const connectConversation = useCallback(async () => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    client.updateSession({
      turn_detection: { type: 'server_vad', silence_duration_ms: 2000 },
    });

    setIsConnected(true);

    await wavRecorder.begin();
    await wavStreamPlayer.connect();
    await client.connect();
    client.sendUserMessageContent([
      {
        type: `input_text`,
        text: `<SYSTEM>User connected</SYSTEM>`,
      },
    ]);

    setTimeout(() => {
      if (client.isConnected()) {
        client.sendUserMessageContent([
          {
            type: `input_text`,
            text: `<SYSTEM>Call time limit met! Politely take the time to inform the user, and respond to anything that they say. Once the user has agreed and acknowledged that the call must end, use the end_call tool. Once you use this tool, it will immediately disconnect the call, so don't call the tool until it feels "appropriate" to do so.<SYSTEM>`,
          },
        ]);
      }
    }, 60 * 1000 * 20);

    await wavRecorder.record((data) => client.appendInputAudio(data.mono));
  }, []);

  const disconnectConversation = useCallback(async () => {
    setIsConnected(false);

    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();

    const items = client.conversation.getItems();
    const sanitizedItems = compact(
      items.map((item) =>
        item.role && item.formatted.transcript
          ? {
              role: item.role,
              text: item.formatted.transcript || '',
            }
          : null
      )
    );

    afterFinish(sanitizedItems);

    client.disconnect();

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();
  }, []);

  // Functions to manage animations
  const setHue = (hue: number) => {
    if (hue !== hueRef.current) {
      hueSpringRef.start({
        hue: hue,
        config: { tension: 50, friction: 50 },
      });
      hueRef.current = hue;
    }
  };

  const setSaturation = (saturation: number) => {
    if (saturation !== saturationRef.current) {
      saturationSpringRef.start({
        saturation: saturation,
        config: { tension: 50, friction: 10 },
      });
      saturationRef.current = saturation;
    }
  };

  const setScale = (scale: number) => {
    scaleSpringRef.start({ scale, config: { tension: 50, friction: 50 } });
    scaleRef.current = scale;
  };

  /**
   * Set up render loops for the visualization canvas
   */
  useEffect(() => {
    let isLoaded = true;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const wavRecorder = wavRecorderRef.current;

    const render = () => {
      if (isLoaded) {
        const client = wavRecorder.recording
          ? wavRecorder.getFrequencies('voice')
          : { values: new Float32Array([0]) };

        const zara = wavStreamPlayer.analyser
          ? wavStreamPlayer.getFrequencies('voice')
          : { values: new Float32Array([0]) };

        const userVolume = Math.max(...client.values);
        const zaraVolume = Math.max(...zara.values);

        zaraVolumeBuffer.current.push(zaraVolume);
        if (zaraVolumeBuffer.current.length > BUFFER_SIZE) {
          zaraVolumeBuffer.current.shift(); // Remove the oldest value
        }

        userVolumeBuffer.current.push(userVolume);
        if (userVolumeBuffer.current.length > BUFFER_SIZE) {
          userVolumeBuffer.current.shift(); // Remove the oldest value
        }

        // Get a value to represent the difference between the user and Zara's speaking volume

        // Calculate the average of the buffer
        const averageZaraVolume =
          zaraVolumeBuffer.current.reduce((sum, val) => sum + val, 0) /
          zaraVolumeBuffer.current.length;

        const averageUserVolume =
          userVolumeBuffer.current.reduce((sum, val) => sum + val, 0) /
          userVolumeBuffer.current.length;

        const volumeDelta = averageZaraVolume - averageUserVolume;

        // Process the smoothed value
        const size = 1 + volumeDelta / 5;
        const zaraSpeed = 0.05 + volumeDelta;

        // Update spring only when necessary (throttling updates)
        setScale(size);

        if (lottieRef.current) {
          (lottieRef.current! as any).setSpeed(zaraSpeed);
        }

        window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  /**
   * Core RealtimeClient and audio capture setup
   * Set all of our instructions, tools, events and more
   */
  useEffect(() => {
    // Get refs
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    // Set instructions
    client.updateSession({ instructions });
    // Set transcription, otherwise we don't get user transcriptions back
    client.updateSession({ input_audio_transcription: { model: 'whisper-1' } });

    client.addTool(
      {
        name: 'end_call',
        description: 'Disconnect the call',
        parameters: {
          type: 'object',
          properties: {},
          required: [],
        },
      },
      () => disconnectConversation()
    );

    client.addTool(
      {
        name: 'set_emotion',
        description:
          "Set the expression of Zara's emotion in response to user input.",
        parameters: {
          type: 'object',
          properties: {
            emotion: {
              type: 'string',
              description: 'The emotion to set',
              enum: Object.keys(emotionHueShifts),
            },
          },
          required: ['emotion'],
        },
      },
      ({ emotion }: { emotion: Emotion; intensity: number }) => {
        const hueShift = emotionHueShifts[emotion];
        setHue(hueShift);
        setSaturation(100);
        setTimeout(() => {
          setSaturation(0);
        }, 10000);
        return { ok: !!hueShift };
      }
    );
    client.on('error', (event: any) => console.error(event));
    client.on('conversation.interrupted', async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });
    client.on('conversation.updated', async ({ item, delta }: any) => {
      // setItems(client.conversation.getItems());

      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      }
      if (item.status === 'completed' && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }
    });

    return () => {
      // cleanup; resets to defaults
      client.reset();
    };
  }, []);

  return (
    <div
      data-component="ConsolePage"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <ZaraOrb
        size={200}
        lottieRef={lottieRef}
        hueSpringRef={hueSpringRef}
        scaleSpringRef={scaleSpringRef}
        saturationSpringRef={saturationSpringRef}
      />
      {children}
      <Button
        isEnabled={isEnabled}
        label={
          openTranscriptModal
            ? 'review transcript'
            : isConnected
            ? 'finish'
            : 'start'
        }
        iconPosition={isConnected ? 'end' : 'start'}
        buttonStyle={isConnected ? 'regular' : 'action'}
        onClick={
          openTranscriptModal ||
          (isConnected ? disconnectConversation : connectConversation)
        }
      />
    </div>
  );
}

import { VideoSection } from '../components/VideoSection';

const videoSources = [
  { title: 'Vaping', src: 'images/impulse-loop/actions/vaping.mp4' },
  { title: 'Watching', src: 'images/impulse-loop/actions/watching.mp4' },
  { title: 'Scrolling', src: 'images/impulse-loop/actions/scrolling.mp4' },
  { title: 'Biting', src: 'images/impulse-loop/actions/biting.mp4' },
  { title: 'Drinking', src: 'images/impulse-loop/actions/drinking.mp4' },
  { title: 'Gaming', src: 'images/impulse-loop/actions/gaming.mp4' },
  { title: 'Smoking', src: 'images/impulse-loop/actions/smoking.mp4' },
  { title: 'Giving up', src: 'images/impulse-loop/actions/giving-up.mp4' },
];

interface Props {
  isActive: boolean;
  onPauseParentLoop: () => void;
}

export function ActionSection({ isActive, onPauseParentLoop }: Props) {
  return (
    <VideoSection
      isActive={isActive}
      onPauseParentLoop={onPauseParentLoop}
      videoSources={videoSources}
      title={<h3 className="text-light">Action</h3>}
    >
      Without even realizing it, you give in. This phase often happens
      automatically, reinforcing the loop.
    </VideoSection>
  );
}

import { SVGProps } from 'react';
export default function ImpulseLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={512}
      height={512}
      viewBox="0 -37 60 70"
      {...props}
    >
      <defs>
        <linearGradient id="b" x1="0%" x2="0%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="rgba(255,255,255,1)" stopOpacity={0.3} />
          <stop offset="100%" stopColor="rgba(255,255,255,0)" />
        </linearGradient>
      </defs>
      {/* <circle cx={30} cy={-2} r={34} fill="#26344e" /> */}
      <path
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeWidth={4}
        d="M0 0c7.379-.04 9.153-.12 9.636-.36.726-.38 2.662-2.9 2.662-3.5 0-.24.241-.54.524-.66.443-.18.685.02 1.25 1.02C16.047-.04 16.41.46 17.217.84c1.25.58 2.54.5 3.548-.18.685-.5.968-1.26 1.613-4.36l.967-5.46.847-5.9.968-6.9c.363-2.44.685-3.8 1.008-3.94.322-.16 1.088 5.94 1.451 11.64C28.627.8 30.04 10.98 31.37 13c.524.82 1.814 1.08 2.661.56.807-.48 1.613-3.54 2.298-8.52.323-2.58.766-5.28.968-6l.403-1.46c0-.48.766.18 1.008.86.444 1.34 2.057 1.82 3.226.94.564-.44 1.855-3.54 2.42-5.94.402-1.62 1.814-4.6 2.217-4.6.524 0 1.451 1.78 2.862 5.4 1.815 4.7 2.863 6.62 3.71 6.88 1.048.34 1.935-.06 2.943-1.3 1.13-1.06 1.13-.06 5.161-.06"
      />
      <circle
        cx={30}
        cy={-2}
        r={32}
        fill="url(#b)"
        stroke="#000"
        strokeWidth={4}
      />
    </svg>
  );
}

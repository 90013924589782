import { addDoc, collection } from 'firebase/firestore';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import Modal from 'react-modal';
import { useDebouncedCallback } from 'use-debounce';
import Footer from '../../components/Footer';
import Header, { HeaderItem } from '../../components/Header';
import ImpulseSection from '../../components/SharedHomepageSection/ImpulseSection';
import TranscriptModal from '../../components/TranscriptModal';
import { db } from '../../firebase';
import { makeScrollToRefFn } from '../../utils/scrollTo';
import BlockingSection from './sections/Blocking';
import BreakTheCycleSection from './sections/BreakTheCycle';
import ConcernsSection from './sections/Concerns';
import InterviewSection from './sections/Interview';
import Leaderboard from './sections/Leaderboard';
import Slider from './sections/Slider';
import TimelineSection from './sections/Timeline';

const description =
  "We're interviewing parents about perspectives on childrens behavioral regulation";

Modal.setAppElement('#root');

export default function ParentsPage() {
  const [isHeaderSticky, setHeaderSticky] = useState(false);
  const [transcriptItems, setTranscriptItems] = useState<
    { role: string; text: string }[]
  >([]);
  const refs = {
    interview: useRef<HTMLDivElement | null>(null),
    blocking: useRef<HTMLDivElement | null>(null),
    impulse: useRef<HTMLDivElement | null>(null),
  };
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleScroll = () => {
    setHeaderSticky(window.scrollY > 100);
  };
  const debouncedHandleScroll = useDebouncedCallback(handleScroll, 500, {
    trailing: true,
  });
  const onOpenModal = () => setModalIsOpen(true);
  const onCloseModal = () => setModalIsOpen(false);
  const handleSubmitButtonClick = () => {
    // Create a new transcript doc
    const docsCollection = collection(db, 'interviews');
    addDoc(docsCollection, {
      transcriptItems,
    }).then(() => {
      setIsSubmitted(true);
      setTranscriptItems([]);
      onCloseModal();
    });
  };
  const onDeleteButtonClick = () => {
    setTranscriptItems([]);
    onCloseModal();
  };

  const afterOpenModal = () => {
    document.querySelector('header')!.style.display = 'none';
    document.body.style.overflow = 'hidden'; // Disable scroll
  };

  const afterCloseModal = () => {
    document.querySelector('header')!.style.display = 'block';
    document.body.style.overflow = ''; // Re-enable scroll
  };

  useEffect(() => {
    (window as any).initCanvas();
    (window as any).SEMICOLON.DocumentOnReady.init();
  }, []);

  const headerItems: HeaderItem[] = [
    {
      type: 'button',
      text: 'Share your perspective',
      onClick: makeScrollToRefFn(refs.interview),
    },
    {
      type: 'link',
      text: 'Contact',
      href: 'mailto:hello@impulse.training',
    },
  ];

  useEffect(() => {
    document.addEventListener('scroll', debouncedHandleScroll);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Parents | Impulse</title>
        <meta name="description" content={description} />
        <meta property="og:title" content="Parents | Impulse" />
        <meta
          property="og:description"
          content="We're interviewing parents about perspectives on childrens behavioral regulation"
        />
      </Helmet>
      <Header isSticky={isHeaderSticky} items={headerItems} />
      <Slider />
      <ConcernsSection scrollToNext={makeScrollToRefFn(refs.blocking)} />
      <BlockingSection
        sectionRef={refs.blocking}
        scrollToNext={makeScrollToRefFn(refs.impulse)}
      />
      <ImpulseSection sectionRef={refs.impulse} />
      {/* <ReplacingSection /> */}
      <TimelineSection
        scrollToImpulseSection={makeScrollToRefFn(refs.impulse)}
        scrollToNext={makeScrollToRefFn(refs.interview)}
      />
      <InterviewSection
        sectionRef={refs.interview}
        transcriptItems={transcriptItems}
        openTranscriptModal={
          transcriptItems.length > 0 ? onOpenModal : undefined
        }
        setTranscriptItems={setTranscriptItems}
        isTranscriptSubmitted={isSubmitted}
      />
      <Leaderboard />
      <BreakTheCycleSection />
      <Footer />
      <TranscriptModal
        onClose={onCloseModal}
        isOpen={modalIsOpen}
        transcriptItems={transcriptItems}
        onDeleteButtonClick={onDeleteButtonClick}
        handleSubmitButtonClick={handleSubmitButtonClick}
      />
    </div>
  );
}

import Modal from '../Modal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  transcriptItems: { role: string; text: string }[];
  onDeleteButtonClick: () => void;
  handleSubmitButtonClick: () => void;
}

export default function TranscriptModal({
  isOpen,
  onClose,
  transcriptItems,
  onDeleteButtonClick,
  handleSubmitButtonClick,
}: Props) {
  return (
    <Modal
      title="Review transcript"
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <div className="modal-footer">
          <div style={{ flexGrow: 1 }}></div>
          <button
            onClick={handleSubmitButtonClick}
            type="button"
            className="btn btn-primary btn-sm"
          >
            Submit anonymously and continue
          </button>
        </div>
      }
    >
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content" style={{ height: '70vh' }}>
          <div className="modal-body">
            {transcriptItems.map((item) => (
              <p className="">
                {item.role === 'user' ? 'You: ' : 'Zara: '}
                {item.text}
              </p>
            ))}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={onDeleteButtonClick}
            >
              Reset
            </button>
            <div style={{ flexGrow: 1 }}></div>
            <button
              onClick={handleSubmitButtonClick}
              type="button"
              className="btn btn-primary btn-sm"
            >
              Submit anonymously and continue
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

import WorldContentSection from '../components/WorldContentSection';

export default function Second() {
  return (
    <WorldContentSection
      title="A weekend that sparks change."
      text="Dive into two immersive 2 hour sessions, with workshops, dynamic discussions, and real-time product testing. Your ideas and insights will help to pave the way."
    />
  );
}

import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { Button } from '../../../../../components/button/Button';
import QuadrantComponent from '../../../../../components/FourQuadrantsLoop/Quadrant';
import './VideoSection.css';

interface Props {
  isActive: boolean;
  title: ReactNode;
  children: ReactNode;
  onPauseParentLoop: () => void;
  videoSources: { title: string; src: string }[];
}

export function VideoSection({
  title,
  children,
  isActive,
  videoSources,
  onPauseParentLoop,
}: Props) {
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0);
  const selectedVideo = videoSources[selectedVideoIndex];
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleButtonClick = useCallback(
    (nextIndex: number) => () => {
      const isDifferentVideo = nextIndex !== selectedVideoIndex;
      setSelectedVideoIndex(nextIndex);

      if (isDifferentVideo && videoRef.current) {
        onPauseParentLoop();
        videoRef.current.srcObject = null; // Clear any existing srcObject
        videoRef.current.src = videoSources[nextIndex].src; // Set new video source
        setTimeout(() => videoRef.current?.play(), 100); // Delay to ensure video is loaded
      }
    },
    []
  );

  useEffect(() => {
    try {
      if (!isActive) {
        videoRef.current?.pause();
      } else {
        setTimeout(() => {
          videoRef.current?.play().catch(() => {});
        }, 100);
      }
    } catch (e) {}
  }, [isActive]);

  // Cleanup when component unmounts
  useEffect(() => {
    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, []);

  const background = (
    <video
      ref={videoRef}
      src={selectedVideo.src}
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        objectFit: 'cover',
      }}
      autoPlay
      playsInline
    />
  );

  return (
    <QuadrantComponent title={title} background={background}>
      {children}
      <div className="video-section-buttons">
        {videoSources.map(({ title }, index) => (
          <Button
            label={title}
            key={index}
            buttonStyle={selectedVideoIndex === index ? 'action' : 'regular'}
            onClick={handleButtonClick(index)}
            style={{ marginRight: '10px' }}
          />
        ))}
      </div>
    </QuadrantComponent>
  );
}

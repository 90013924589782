import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { Formik } from 'formik';
import { useCallback, useState } from 'react';
import LocationAutocomplete from '../../../../components/LocationAutocomplete';
import { db } from '../../../../firebase';

interface Props {
  sectionRef: React.RefObject<HTMLDivElement>;
}

interface Values {
  contactDetails: string;
  city: string;
  message: string;
  ageRange: string;
}
const initialValues: Values = {
  contactDetails: '',
  city: '',
  message: '',
  ageRange: '',
};

export default function ApplySection({ sectionRef }: Props) {
  const [serverErrors, setServerError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = useCallback(async (values: Values) => {
    setIsLoading(true);
    try {
      await addDoc(collection(db, 'pioneerSubmissions'), {
        ...values,
        createdAt: Timestamp.now(),
      });
      setIsSubmitted(true);
    } catch (error: any) {
      console.error(error);
      setServerError(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, handleChange, handleSubmit }) => (
        <form className="m-0">
          <div
            ref={sectionRef}
            className="section m-0 p-0"
            id="apply-section"
            style={{ scrollMarginTop: '200px' }}
          >
            <div
              id="processing-indicator"
              className="form-process"
              style={{ display: isLoading ? 'block' : undefined }}
            >
              <div className="css3-spinner">
                <div className="css3-spinner-scaler"></div>
              </div>
            </div>
            <div className="container mb-0 py-6">
              <div className="heading-block text-center">
                {isSubmitted ? (
                  <h3>
                    <span>Thank you</span> for expressing your interest!
                  </h3>
                ) : (
                  <h3>
                    Express your interest in <span>joining us</span>
                  </h3>
                )}
              </div>

              {isSubmitted ? (
                <div
                  className="promo parallax scroll-detect promo-dark bg-color promo-full mb-5 scroll-detect-inview"
                  style={
                    {
                      '--cnvs-scroll-height': 233,
                      '--cnvs-scroll-percent': 57.09298780487805,
                      '--cnvs-scroll-ratio': 1,
                      '--cnvs-scroll-start': 1,
                      '--cnvs-scroll-end': 0,
                    } as any
                  }
                >
                  <img src="images/parallax/7.jpg" className="parallax-bg" />
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-12 col-lg text-center">
                        <h3>
                          We're so excited that you're interested in joining us{' '}
                          <span className="text-warning">on this mission</span>.
                        </h3>
                        <span>
                          We'll be in touch with more information using the
                          contact details that you provided!
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="subscribe-widget">
                  <div className="widget-subscribe-form-result"></div>
                  <div className="row">
                    <div className="col-md-5 form-group">
                      <label htmlFor="contact-details-input">
                        Your city / state / country <small>*</small>
                      </label>
                      <LocationAutocomplete
                        afterChange={handleChange('city')}
                      />
                    </div>
                    <div className="col-md-3 form-group">
                      <label htmlFor="contact-details-input">
                        Age range <small>*</small>
                      </label>
                      <select
                        className="form-control"
                        value={values.ageRange}
                        required
                        onChange={handleChange('ageRange')}
                      >
                        <option value="">Please select</option>
                        <option value="14">Under 14</option>
                        <option value="14-18">14-18</option>
                        <option value="18-21">18-21</option>
                      </select>
                    </div>
                    <div className="col-md-4 form-group">
                      <label htmlFor="contact-details-input">
                        Email or phone <small>*</small>
                        {values.ageRange === '14-18' && ' (parent/guardian)'}
                      </label>
                      <input
                        type="text"
                        required
                        name="contact-details-input"
                        value={values.contactDetails}
                        className="required email form-control"
                        onChange={handleChange('contactDetails')}
                      />
                    </div>
                  </div>

                  <div className="col-12 form-group">
                    <label htmlFor="template-contactform-message">
                      Message <small>*</small>
                    </label>
                    <textarea
                      className="required form-control valid"
                      placeholder="What interests you about the Impulse Pioneer Program?"
                      rows={6}
                      cols={30}
                      value={values.message}
                      onChange={handleChange('message')}
                    ></textarea>
                  </div>

                  {values.ageRange === '14' ? (
                    <div className="form-group">
                      <label>
                        Sorry! At this stage we're only working with people aged
                        14-21
                      </label>
                    </div>
                  ) : null}

                  {values.ageRange === '14-18' ? (
                    <div className="form-group">
                      <label>
                        We're so glad you want to join us! Please get your
                        parent or guardian to fill out the form with you.
                      </label>
                    </div>
                  ) : null}

                  {serverErrors ? (
                    <div className="form-group">
                      <label className="error">{serverErrors}</label>
                    </div>
                  ) : null}
                  <button
                    className="button button-3d m-0"
                    type="button"
                    disabled={values.ageRange === '14'}
                    onClick={() => handleSubmit()}
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

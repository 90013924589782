import './CountUpBlock.css';

interface Props {
  onClick: () => void;
  countFrom: number;
  countTo: number;
  text: string;
  isActive: boolean;
  className?: string;
}

export default function CountUpBlock({
  countFrom,
  countTo,
  text,
  onClick,
  isActive,
  className,
}: Props) {
  return (
    <div
      onClick={onClick}
      className={`count-up-block ${
        isActive ? 'active' : null
      } col-md-4 col-lg-2 text-center ${className}`}
    >
      <div className="counter counter-large text-light">
        <span
          data-from={String(countFrom)}
          data-to={countTo}
          data-refresh-interval="80"
          data-speed="2000"
          data-comma="true"
        >
          {countFrom}
        </span>
      </div>
      <h5 className="mb-0 text-light">{text}</h5>
    </div>
  );
}

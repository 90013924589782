import WorldContentSection from '../components/WorldContentSection';

export default function First() {
  return (
    <WorldContentSection
      title="3 Pioneer Cities from around the world"
      text="We’re bringing Impulse to three cities where the energy and interest are highest. Help your city become a launchpad for innovation and change!"
    />
  );
}

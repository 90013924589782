import { useEffect } from 'react';
import ImpulseLogo from '../../../../components/ImpulseLogo';
import './ThePlan.css';

interface Props {
  sectionRef: React.RefObject<HTMLDivElement>;
}

export default function AreYouAFit({ sectionRef }: Props) {
  useEffect(() => {
    const jQuery = (window as any).jQuery;

    document
      .querySelectorAll('.block-sticky-cards-wrap .sticky-section')
      .forEach(function (element, index) {
        jQuery(element).css({
          '--sticky-top-gap': `${30 * index}px`,
        });
      });
  }, []);

  return (
    <section ref={sectionRef} id="content" className="block-sticky-cards">
      <div className="content-wrap py-0">
        <div className="section bg-white">
          <div className="container">
            <div className="row align-items-start justify-content-between">
              <div className="col-lg-6 sticky-section sticky-lg-top">
                <h1 className="display-4 fw-bold font-secondary mt-4">
                  Are you a fit for the Impulse Pioneer Program?
                </h1>
                <p>
                  What Do You Bring to the Table? A willingness to share your
                  ideas and perspective. Creativity and a desire to collaborate
                  with other like-minded young people. An open mind to learning
                  and exploring innovative solutions.
                </p>
              </div>
              <div className="col-lg-5 block-sticky-cards-wrap">
                <div className="sticky-section sticky-lg-top bg-white">
                  <div className="card border border-color bg-color bg-opacity-10">
                    <div className="card-body p-5 min-vh-50 d-flex flex-column justify-content-center text-center">
                      <i className="bi-person fs-1 mb-3 text-black"></i>
                      <span className="badge rounded-circle p-3 mt-3 ms-3 bg-dark position-absolute top-0 start-0">
                        01
                      </span>
                      <h3>Between 14 and 21 years old? </h3>
                      We’re looking for young people who are ready to shape the
                      future of behavioral regulation tools. Impulse is an app
                      for the future of humanity... And you represent the future
                      of humanity!
                    </div>
                  </div>
                </div>

                <div className="sticky-section sticky-lg-top bg-white">
                  <div className="card border border-danger bg-danger bg-opacity-10">
                    <div className="card-body p-5 min-vh-50 d-flex flex-column justify-content-center text-center">
                      <span className="mb-3 ">
                        <ImpulseLogo width={44} height={44} />
                      </span>
                      <span className="badge rounded-circle p-3 mt-3 ms-3 bg-dark position-absolute top-0 start-0">
                        02
                      </span>
                      <h3>Have experience with impulses?</h3>
                      Whether it’s battling cravings, procrastination, or even
                      managing screen time, if you have first-hand experience
                      with an "impulse", this is a big plus. While not a strict
                      requirement, familiarity with at least one kind of impulse
                      would help you better understand the challenges we’re
                      addressing, and perhaps contribute more meaningful ideas
                      and feedback.
                    </div>
                  </div>
                </div>

                <div className="sticky-section sticky-lg-top bg-white">
                  <div className="card border border-warning bg-warning bg-opacity-10">
                    <div className="card-body p-5 min-vh-50 d-flex flex-column justify-content-center text-center">
                      <i className="bi-heart-fill fs-1 mb-3 text-black"></i>
                      <span className="badge rounded-circle p-3 mt-3 ms-3 bg-dark position-absolute top-0 start-0">
                        03
                      </span>
                      <h3>Willing to contribute ideas and your perspective?</h3>
                      You don’t need to share personal details or experiences if
                      you’re not comfortable—what matters most is your feedback,
                      suggestions, and innovative thinking. Ready to contribute?
                      Let’s build something amazing together!
                    </div>
                  </div>
                </div>

                <div className="sticky-section sticky-lg-top bg-white">
                  <div className="card border border-info bg-info bg-opacity-10">
                    <div className="card-body p-5 min-vh-50 d-flex flex-column justify-content-center text-center">
                      <i className="bi-rocket-takeoff fs-1 mb-3 text-black"></i>
                      <span className="badge rounded-circle p-3 mt-3 ms-3 bg-dark position-absolute top-0 start-0">
                        04
                      </span>
                      <h3>Able to participate in two in-person sessions?</h3>
                      We’ll meet in-person as a group for 2 hands-on workshops
                      in your city, with brainstorming sessions, activities, and
                      real-time feedback opportunities. The Impulse Pioneer
                      Program is for young people who are keen to show up, get
                      their hands dirty, and help us to make Impulse the best it
                      can be!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// Import the functions you need from the SDKs you need
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyAwLVnnXMq6L6VLboLigB3bQGe-JKGNA0M',
  authDomain: 'impulse-tmp.firebaseapp.com',
  projectId: 'impulse-tmp',
  storageBucket: 'impulse-tmp.appspot.com',
  messagingSenderId: '199765336595',
  appId: '1:199765336595:web:fba6c650357fdb61a055e6',
  measurementId: 'G-54V3GG193P',
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
getAnalytics(app);

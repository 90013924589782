import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { Formik } from 'formik';
import { useCallback, useRef } from 'react';
import { db } from '../../firebase';
import Modal from '../Modal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const initialValues = { email: '' };

export default function WaitlistModal({ isOpen, onClose }: Props) {
  const resultRef = useRef<HTMLDivElement>(null);
  const onSubmit = useCallback(async (values: typeof initialValues) => {
    addDoc(collection(db, 'emailSubscriptions'), {
      email: values.email,
      createdAt: Timestamp.now(),
    });
    (window as any).SEMICOLON.Modules.notifications(resultRef.current);
    onClose();
  }, []);

  return (
    <Modal
      title="Join our waitlist to get updates"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="widget">
        <div
          ref={resultRef}
          data-notify-type="success"
          data-notify-msg="Thanks! We'll keep you updated."
          className="widget-subscribe-form-result"
        ></div>
        <Formik onSubmit={onSubmit} initialValues={initialValues}>
          {({ values, handleChange, handleSubmit }) => (
            <div className="input-group mx-auto">
              <input
                type="text"
                value={values.email}
                onChange={handleChange('email')}
                className="form-control"
                placeholder="Enter your Email"
                required
              />
              <button
                onClick={() => handleSubmit()}
                className="btn btn-success"
                type="submit"
              >
                <i className="bi-send"></i>
              </button>
            </div>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

import { MouseEventHandler, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

interface Props {
  scrollToNext: MouseEventHandler;
}

export default function ConcernsSection({ scrollToNext }: Props) {
  const [hasTriggered, setHasTriggered] = useState(false);

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.7,
  });

  useEffect(() => {
    if (!hasTriggered && inView) {
      setHasTriggered(true);
      return;
    }
    const header = document.getElementById('header');
    if (inView) header?.classList.add('sticky-header');
  }, [inView]);

  return (
    <div ref={ref} className="row align-items-center">
      <div className="col-md-5 offset-md-1 p-5">
        <h3 className="text-stretch display-6 fw-bold position-relative">
          A Generation at a Crossroads
        </h3>
        <p className="lead">
          Young people today are surrounded by more and more potent sources of
          stimulation, including the internet, gaming, and social media. It's a
          lot for our human brains to handle, but especially for
          still-developing ones.
        </p>
        <button
          onClick={scrollToNext}
          className="btn btn-lg btn-dark rounded-0 mx-0 px-4 mt-4"
        >
          So what can parents do?
        </button>
      </div>

      <div
        className="col-md-6 order-md-2 min-vh-md-100 min-vh-40"
        style={{
          backgroundSize: 'cover',
          backgroundImage: "url('images/blocks/preview/hooked.jpg')",
        }}
      ></div>
    </div>
  );
}

export default function PhoneBg() {
  return (
    <div className="phone_bg-combined">
      <img
        src="images/phone_bg/left.svg"
        loading="lazy"
        alt=""
        className="phone_bg-image"
      />
      <img
        src="images/phone_bg/middle.svg"
        loading="lazy"
        alt=""
        className="phone_bg-image"
      />
      <img
        src="images/phone_bg/right.svg"
        loading="lazy"
        alt=""
        className="phone_bg-image"
      />
    </div>
  );
}

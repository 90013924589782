import { useEffect, useRef } from 'react';
import QuadrantComponent from '../../../../../components/FourQuadrantsLoop/Quadrant';

interface Props {
  isActive: boolean;
}

export default function RewardSection({ isActive }: Props) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const background = (
    <video
      ref={videoRef}
      src={'images/impulse-loop/reward.mp4'}
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        objectFit: 'cover',
      }}
      autoPlay={isActive}
      playsInline
    />
  );

  useEffect(() => {
    try {
      if (!isActive) {
        videoRef.current?.pause();
      } else {
        setTimeout(() => {
          videoRef.current?.play().catch(() => {});
        }, 100);
      }
    } catch (e) {}
  }, [isActive]);

  return (
    <QuadrantComponent
      background={background}
      title={<h3 className="text-light">Reward</h3>}
    >
      Dopamine floods the nucleus accumbens in your brain, creating the
      sensation of pleasure or relief. Over time, this part of the brain becomes
      less sensitive to dopamine, requiring greater stimulation to achieve the
      same "high" (tolerance).
    </QuadrantComponent>
  );
}

import { useState } from 'react';
import PrivacyModal from '../PrivacyModal';
import SubscribeForm from '../SubscribeForm';
import Zara from '../Zara';

type Props = {
  openTranscriptModal: undefined | (() => void);
  setTranscriptItems: (items: { role: string; text: string }[]) => void;
  onCancelButtonClick: () => void;
  isTranscriptSubmitted: boolean;
};

export default function EmblaCarousel({
  openTranscriptModal,
  onCancelButtonClick,
  setTranscriptItems,
  isTranscriptSubmitted,
}: Props) {
  const [areTermsAccepted, setAreTermsAccepted] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const toggleTermsAccepted = () => setAreTermsAccepted((e) => !e);
  const togglePrivacyModalOpen = () => setIsPrivacyModalOpen((e) => !e);

  return (
    <section
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isSubscribed ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h1>Thank you connecting with us ♥️</h1>
          <a
            className="embla__next_button"
            href="mailto:hello@impulse.training"
            style={{ marginBottom: '1rem' }}
          >
            Send feedback by email
          </a>
          <button
            className="embla__next_button"
            style={{ marginBottom: '1rem' }}
            onClick={onCancelButtonClick}
          >
            Book a time with the team
          </button>
        </div>
      ) : isTranscriptSubmitted ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h1>Thank you for your time! </h1>
          <p>
            Would you like to be kept in the loop about the Impulse Pioneer
            Program in your city?
          </p>
          <SubscribeForm setIsSubscribed={setIsSubscribed} />
        </div>
      ) : (
        <div>
          <Zara
            isEnabled={areTermsAccepted}
            openTranscriptModal={openTranscriptModal}
            afterFinish={setTranscriptItems}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              className="mb-4"
            >
              <div className="switch m-0">
                <input
                  id="switch-toggle-2"
                  className="switch-toggle switch-toggle-round pr-2"
                  type="checkbox"
                  checked={areTermsAccepted}
                  onChange={toggleTermsAccepted}
                />
                <label className="my-0 mx-2" htmlFor="switch-toggle-2"></label>
              </div>
              <span>
                I accept the{' '}
                <a
                  className="m-0 btn btn-link text-primary"
                  onClick={togglePrivacyModalOpen}
                >
                  privacy policy
                  <i className="uil-arrow-up-right" />
                </a>
              </span>
            </div>
          </Zara>
        </div>
      )}
      <PrivacyModal
        isOpen={isPrivacyModalOpen}
        onClose={() => setIsPrivacyModalOpen(false)}
      />
    </section>
  );
}

import { useState } from 'react';
import WaitlistModal from '../../../../components/WaitlistModal';

export default function GameplanSection() {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <div className="row justify-content-between align-items-center py-md-6">
      <div className="col-lg-5">
        <div className="col-padding">
          <h2 className="fw-semibold mb-3">Your Gameplan for Overcoming</h2>
          <p className="lead mb-1">
            Add your own tactics or get encouragement from your support people.
          </p>
          <p className="lead mb-5">
            Always available in the moments that you need it.
          </p>
          <button
            className="btn px-5 py-3 btn-dark rounded-0"
            onClick={() => setModalOpen(true)}
          >
            Join Waitlist
          </button>
        </div>
      </div>

      <div className="col-lg-7">
        <div
          id="oc-images"
          className="owl-carousel image-carousel carousel-widget"
          data-nav="false"
          data-margin="20"
          data-items-xs="2"
          data-items-sm="3"
          data-items-md="4"
          data-items-lg="3"
          data-items-xl="3"
        >
          <div className="oc-item">
            <a href="demo-hostel.html">
              <img src="images/gameplan/tactic1.png" alt="Image 1" />
            </a>
          </div>
          <div className="oc-item">
            <a href="demo-blog.html">
              <img src="images/gameplan/tactic2.png" alt="Image 2" />
            </a>
          </div>
          <div className="oc-item">
            <a href="demo-forum.html">
              <img src="images/gameplan/tactic3.png" alt="Image 3" />
            </a>
          </div>
          <div className="oc-item">
            <a href="demo-landing.html">
              <img src="images/gameplan/tactic4.png" alt="Image 4" />
            </a>
          </div>
          <div className="oc-item">
            <a href="http://themes.semicolonweb.com/html/canvas/intro.html#section-niche">
              <img src="images/gameplan/tactics.png" alt="Image 5" />
            </a>
          </div>
        </div>
      </div>
      <WaitlistModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </div>
  );
}

interface Props {
  title: string;
  text: string;
}

export default function WorldContentSection({ title, text }: Props) {
  return (
    <div
      style={{
        color: 'white',
        background: 'rgba(0,0,0,0.5)',
        borderRadius: 10,
        padding: 50,
      }}
    >
      <h1 className="text-light">{title}</h1>
      {text}
    </div>
  );
}

import BreakTheCycle from '../../../../components/BreakTheCycle';

export default function BreakTheCycleSection() {
  return (
    <div style={{ position: 'relative' }}>
      <img src="images/family.jpeg" />
      <div
        style={{
          position: 'absolute',
          top: 50,
          width: '100%',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          className="logo-default"
          srcSet="images/logo.png, images/logo@2x.png 2x"
          src="one-page/images/canvasone@2x.png"
          style={{ width: 140 }}
        />
        <BreakTheCycle stroke="white" />
      </div>
    </div>
  );
}

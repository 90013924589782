import QuadrantComponent from '../../../../../components/FourQuadrantsLoop/Quadrant';

export default function ImpulseSection() {
  const background = (
    <img
      src={'images/impulse-loop/brain.png'}
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        opacity: 0.4,
        objectFit: 'cover',
      }}
    />
  );
  return (
    <QuadrantComponent
      background={background}
      title={<h3 className="text-light">Impulse</h3>}
    >
      The impulse is the craving or urge. Whether it's for checking your phone,
      biting your nails, or reaching for a snack, the Impulse can be powerful
      and hard to ignore. Rational abilities are reduced, and impulsivity is
      increased.
    </QuadrantComponent>
  );
}

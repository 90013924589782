import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

const impulseAnimation = require('../../../assets/animations/impulse.json');

interface Props {
  sectionRef: React.RefObject<HTMLDivElement>;
}

export default function ImpulseSection({ sectionRef }: Props) {
  const aboutSectionRef = useRef<HTMLDivElement>(null);
  const { ref, inView } = useInView({
    triggerOnce: false,
    delay: 1200,
    threshold: 0.3,
  });
  const lottieRef = useRef<LottieRefCurrentProps>(null);

  useEffect(() => {
    if (inView) {
      lottieRef.current?.play();
    } else {
      lottieRef.current?.goToAndStop(0);
    }
  }, [inView]);

  const handleImpulseButtonClick = () =>
    aboutSectionRef.current?.scrollIntoView({ behavior: 'smooth' });

  return (
    <div ref={sectionRef} className="section pt-6 bg-white">
      <div ref={ref} className="container">
        <div className="block-hero-22 pt-4">
          <div className="row justify-content-center mb-6">
            <div className="col-md-5 text-center">
              <Lottie
                lottieRef={lottieRef}
                animationData={impulseAnimation}
                loop={false}
                autoplay={false}
                width={'100%'}
                onClick={handleImpulseButtonClick}
              />
            </div>
            <div
              className="col-md-7"
              style={{
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <h2 className="h1 fw-semibold display">
                We're building Impulse to help break the cycle
              </h2>
              <p className="lead text-dark op-07 fw-normal">
                When you feel the impulse, press the Impulse widget on your
                phone's lock screen. It's always available to help guide you
                towards a values-aligned decision.
              </p>
            </div>
          </div>
          <div
            ref={aboutSectionRef}
            style={{ scrollMarginTop: '200px' }}
            id="about"
            className="row justify-content-between col-mb-50 gutter-50"
          >
            <div className="col-6 col-lg-3 text-center">
              <img
                src="demos/seo/images/icons/experience.svg"
                alt="Feature Icon"
                className="bg-transparent rounded-0 feature-icon"
              />
              <h4 className="my-3">Live support from Zara</h4>
              <p className="text-dark op-07 fw-normal mb-4">
                Zara, our AI coach, is always available to chat through impulse
                moments and help to guide you based on your values.
              </p>
            </div>
            <div className="col-6 col-lg-3 text-center">
              <img
                src="demos/seo/images/icons/content_marketing.svg"
                alt="Feature Icon"
                className="bg-transparent rounded-0 feature-icon"
              />
              <h4 className="my-3">Reflect on your state</h4>
              <p className="text-dark op-07 fw-normal mb-4">
                Answer questions to understand what triggered your impulse, and
                what you might actually need in the moment.
              </p>
            </div>
            <div className="col-6 col-lg-3 text-center">
              <img
                src="demos/seo/images/icons/analysis.svg"
                alt="Feature Icon"
                className="bg-transparent rounded-0 feature-icon"
              />
              <h4 className="my-3">Deploy strategies</h4>
              <p className="text-dark op-07 fw-normal mb-4">
                Try distractions, CBT techniques, movement or breathing
                exercises, and more, to help you overcome the impulse.
              </p>
            </div>
            <div className="col-6 col-lg-3 text-center">
              <img
                src="demos/seo/images/icons/maam.png"
                alt="Feature Icon"
                className="bg-transparent rounded-0 feature-icon"
              />

              <h4 className="my-3">Track and improve</h4>
              <p className="text-dark op-07 fw-normal mb-4">
                Debrief each impulse moment as a success or a setback, and track
                progress over time. You might also like to share your wins with
                support people!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

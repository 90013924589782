import {
  ComponentType,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import './QuadrantLoop.css';

type Quadrant = ComponentType<{
  isActive: boolean;
  onPauseParentLoop: () => void;
}>;

interface Props {
  First: Quadrant;
  Second: Quadrant;
  Third: Quadrant;
  Fourth: Quadrant;
  duration?: number;
  initialQuadrant: number;
  setInitialQuadrant: Dispatch<SetStateAction<number>>;
  activeQuadrant: number;
  setActiveQuadrant: Dispatch<SetStateAction<number>>;
  startTime: number;
  setStartTime: Dispatch<SetStateAction<number>>;
}

export default function FourQuadrantsLoop({
  First,
  Second,
  Third,
  Fourth,
  duration = 40000,
  initialQuadrant,
  setInitialQuadrant,
  startTime,
  setStartTime,
  activeQuadrant,
  setActiveQuadrant,
}: Props) {
  const [isPaused, setIsPaused] = useState(false);
  const [pauseStartTime, setPauseStartTime] = useState<number | null>(null);
  const armRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setStartTime(performance.now());
  }, [initialQuadrant]);

  const onPauseParentLoop = useCallback(() => {
    setIsPaused(true);
    setPauseStartTime(performance.now());
  }, []);

  useEffect(() => {
    let animationFrameId: number;

    const rotateArm = (currentTime: number) => {
      if (!armRef.current) return;

      if (!isPaused) {
        const elapsed = currentTime - startTime;
        const rotation =
          ((elapsed % duration) / duration) * 360 + initialQuadrant * 90 + 90;
        armRef.current.style.transition = 'none';
        armRef.current.style.transform = `rotate(${rotation}deg)`;

        const adjustedRotation = (rotation - 90 + 360) % 360;
        const quadrant = Math.floor(adjustedRotation / 90) % 4;

        setActiveQuadrant(quadrant);
      }

      animationFrameId = requestAnimationFrame(rotateArm);
    };

    animationFrameId = requestAnimationFrame(rotateArm);

    return () => cancelAnimationFrame(animationFrameId);
  }, [startTime, initialQuadrant, isPaused]);

  const handleQuadrantClick = (quadrantIndex: number) => {
    if (isPaused) {
      // Unpause the animation
      if (pauseStartTime !== null) {
        const pausedDuration = performance.now() - pauseStartTime;
        setStartTime((prevStartTime) => prevStartTime + pausedDuration);
        setPauseStartTime(null);
      }
      setIsPaused(false);

      if (quadrantIndex !== activeQuadrant) {
        // Jump to the new quadrant
        setInitialQuadrant(quadrantIndex);
        setActiveQuadrant(quadrantIndex);
      }
    } else {
      if (quadrantIndex === activeQuadrant) {
        // Pause the animation
        onPauseParentLoop();
      } else {
        // Jump to the clicked quadrant and continue
        setInitialQuadrant(quadrantIndex);
        setActiveQuadrant(quadrantIndex);
      }
    }
  };

  const quadrants = [
    {
      positionClassName: 'top-left',
      quadrantIndex: 0,
      Component: First,
    },
    {
      positionClassName: 'top-right',
      quadrantIndex: 1,
      Component: Second,
    },
    {
      positionClassName: 'bottom-right',
      quadrantIndex: 2,
      Component: Third,
    },
    {
      positionClassName: 'bottom-left',
      quadrantIndex: 3,
      Component: Fourth,
    },
  ];

  return (
    <div id="quadrant-loop" className="quadrant-loop-container">
      {quadrants.map(({ positionClassName, quadrantIndex, Component }) => (
        <div
          className={`quadrant ${positionClassName} ${
            activeQuadrant === quadrantIndex ? 'active' : ''
          }`}
          onClick={() => handleQuadrantClick(quadrantIndex)}
        >
          <Component
            onPauseParentLoop={onPauseParentLoop}
            isActive={activeQuadrant === quadrantIndex}
          />
        </div>
      ))}
      <div className="glowing-circle">
        <div className="center-circle"></div>
        <div className="arm" ref={armRef}></div>
      </div>
    </div>
  );
}

export default function Footer() {
  return (
    <footer id="footer" className="dark">
      <div className="container">
        <div className="footer-widgets-wrap">
          <div className="row">
            <div className="col-md-4">
              <div className="widget clearfix">
                <img
                  src="images/logo-footer.png"
                  alt="Image"
                  className="footer-logo"
                  style={{ width: 120 }}
                />
                <p className="mb-0">
                  We believe in the potential of every human being.
                </p>
              </div>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <div
                style={{
                  background:
                    "url('images/world-map.png') no-repeat center center",
                  backgroundSize: '100%',
                  textAlign: 'right',
                }}
              >
                <address>
                  <strong>Come and talk to us at</strong>
                  <br />
                  405 N Jefferson Ave, Springfield, <br />
                  MO 65806
                </address>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dark my-0 clearfix">
        <div className="container clearfix">
          <div className="center border-bottom-0 mb-0">
            <h4 className="fw-medium"></h4>
          </div>
        </div>
      </div>

      <div id="copyrights">
        <div className="container">
          <div className="row col-mb-30">
            <div className="col-md-6 text-center text-md-start">
              Copyright &copy; 2024 All Rights Reserved by Impulse Training,
              Inc.
              <br />
              {/* <!-- <div className="copyright-links">
            <a href="terms.html">Terms of Service</a> /
            <a href="privacy.html">Privacy Policy</a>
          </div> --> */}
            </div>

            <div className="col-md-6 text-center text-md-end">
              {/* <!-- <div
            className="d-flex justify-content-center justify-content-md-end"
          >
            <a
              href="#"
              className="social-icon si-small si-borderless si-facebook"
            >
              <i className="icon-facebook"></i>
              <i className="icon-facebook"></i>
            </a>
            <a
              href="#"
              className="social-icon si-small si-borderless si-twitter"
            >
              <i className="icon-twitter"></i>
              <i className="icon-twitter"></i>
            </a>
            <a
              href="#"
              className="social-icon si-small si-borderless si-github"
            >
              <i className="icon-github"></i>
              <i className="icon-github"></i>
            </a>
            <a
              href="#"
              className="social-icon si-small si-borderless si-linkedin"
            >
              <i className="icon-linkedin"></i>
              <i className="icon-linkedin"></i>
            </a>
          </div>

          <div className="clear"></div> --> */}
              <i className="icon-envelope2"></i>
              hello@impulse.training <span className="middot">&middot;</span>
              <i className="icon-headphones"></i> +1-720-291-2089
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

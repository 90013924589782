import { useEffect } from 'react';
import { initialize } from './initialize';

export default function Slider() {
  useEffect(() => {
    initialize();
  }, []);

  const onVideoOverlayClick = (event: any) => {
    event.target.parentElement.querySelector('video').play();
  };

  return (
    <section
      id="slider"
      className="slider-element revslider-wrap h-auto include-header"
    >
      <div
        id="rev_slider_430_1_wrapper"
        className="rev_slider_wrapper fullscreen-container"
        data-alias="rock-band-music"
        style={{ backgroundColor: 'transparent', padding: 0 }}
      >
        <div
          id="rev_slider_430_1"
          className="rev_slider fullscreenbanner"
          style={{ display: 'none' }}
          data-version="5.2.1"
        >
          <ul>
            <li
              data-index="rs-1494"
              className="dark"
              data-transition="fade"
              data-slotamount="default"
              data-hideafterloop="0"
              data-hideslideonmobile="off"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="1000"
              data-rotate="0"
              data-saveperformance="off"
              data-title="Slide"
              data-param1=""
              data-param2=""
              data-param3=""
              data-param4=""
              data-param5=""
              data-param6=""
              data-param7=""
              data-param8=""
              data-param9=""
              data-param10=""
              data-description=""
            >
              <img
                src="images/videos/placeholder.png"
                alt="Image"
                data-bgposition="center center"
                data-bgfit="cover"
                data-bgparallax="5"
                className="rev-slidebg"
                data-no-retina
              />
              <div
                className="rs-background-video-layer"
                data-forcerewind="on"
                data-volume="mute"
                data-videowidth="100%"
                data-videoheight="100%"
                data-videomp4="images/videos/generations.mp4"
                data-videopreload="auto"
                data-videoloop="loop"
                data-forcecover="1"
                data-aspectratio="16:9"
                onClick={onVideoOverlayClick}
                data-autoplay="true"
              ></div>
              <div
                className="video-overlay"
                onClick={onVideoOverlayClick}
              ></div>

              <div
                className="tp-caption tp-resizeme rs-parallaxlevel-6"
                id="slide-365-layer-11"
                data-x="['center','center','center','left']"
                data-hoffset="['-250','-200','-180','40']"
                data-y="['middle','middle','middle','top']"
                data-voffset="['-100','-80','-120','0']"
                data-fontsize="['100','70','60','60']"
                data-lineheight="['150','100','90','60']"
                data-letterspacing="['-5','-3','-3','-2']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":700,"speed":2000,"frame":"0","from":"y:50px;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                data-paddingtop="[0,0,0,0]"
                data-bgparallax="55"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: 14,
                  whiteSpace: 'nowrap',
                  fontSize: 100,
                  lineHeight: 150,
                  fontWeight: 700,
                  color: '#ffffff',
                  letterSpacing: -5,
                  fontFamily: 'Poppins',
                  textShadow: '0px 15px 30px rgba(150, 150, 150, 0.25)',
                }}
              >
                hi,
              </div>
              <div
                className="tp-caption tp-resizeme rs-parallaxlevel-7"
                id="slide-365-layer-11"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['middle','middle','middle','top']"
                data-voffset="['0','0','-50','80']"
                data-fontsize="['150','100','100','100']"
                data-lineheight="['150','100','90','50']"
                data-letterspacing="['-5','-3','-3','-2']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":700,"speed":2000,"frame":"0","from":"y:50px;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: 14,
                  whiteSpace: 'nowrap',
                  fontSize: 150,
                  lineHeight: 150,
                  fontWeight: 700,
                  color: '#ffffff',
                  letterSpacing: -5,
                  fontFamily: 'Poppins',
                  textShadow: '0px 15px 30px rgba(150, 150, 150, 0.25)',
                }}
              >
                parent.
              </div>
              <div
                className="tp-caption tp-resizeme rs-parallaxlevel-9"
                id="slide-365-layer-12"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['150','150','100','0']"
                data-fontsize="['28','28','28','36']"
                data-lineheight="['35','35','35','40']"
                data-width="['560','560','560','440']"
                data-height="none"
                data-whitespace="normal"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":800,"speed":2000,"frame":"0","from":"y:50px;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['center','center','center','center']"
                data-bgparallax="5"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: 110,
                  whiteSpace: 'normal',
                  fontSize: 30,
                  lineHeight: 35,
                  fontWeight: 400,
                  color: '#ffffff',
                  letterSpacing: 0,
                  fontFamily: 'Poppins',
                }}
                onClick={onVideoOverlayClick}
              >
                Are you trying to help your child build a healthy relationship
                with technology?
              </div>

              <a
                className="tp-caption rev-btn tp-resizeme rs-parallaxlevel-12"
                href="#"
                id="slide-365-layer-13"
                data-x="['center','center','center','center']"
                data-hoffset="['-150','-150','-150','0']"
                data-y="['middle','middle','middle','bottom']"
                data-voffset="['240','240','240','30']"
                data-fontsize="['20','20','20','30']"
                data-lineheight="['60','60','60','60']"
                data-width="['275','275','275','400']"
                data-height="none"
                data-whitespace="nowrap"
                data-type="button"
                data-actions='[{"event":"click","action":"scrollbelow","offset":"","delay":"","speed":"1500","ease":"Power1.easeInOut"}]'
                data-responsive_offset="on"
                data-frames='[{"delay":900,"speed":2000,"frame":"0","from":"y:50px;opacity:0;fb:10px;fbr:100;","to":"o:1;fb:0;fbr:100;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;fbr:100;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"200","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;fb:0;fbr:110%;","style":"c:rgba(255,255,255,1);bs:solid;bw:0 0 0 0;"}]'
                data-scrollto="#explanation"
                data-textAlign="['center','center','center','center']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: 12,
                  minWidth: 300,
                  maxWidth: 300,
                  whiteSpace: 'nowrap',
                  fontSize: 20,
                  lineHeight: 60,
                  fontWeight: '600',
                  color: 'rgba(255, 255, 255, 1)',
                  fontFamily: 'Poppins',
                  backgroundColor: '#8c107f',
                  borderColor: 'rgba(0, 0, 0, 1)',
                  borderRadius: '30px 30px 30px 30px',
                  outline: 'none',
                  boxShadow: 'none',
                  boxSizing: 'border-box',
                  cursor: 'pointer',
                }}
              >
                Yes, of course! <i className="fa-solid fa-caret-down"></i>
              </a>

              <a
                className="tp-caption rev-btn tp-resizeme rs-parallaxlevel-12"
                id="slide-365-layer-13"
                data-x="['center','center','center','center']"
                data-hoffset="['150','150','150','0']"
                data-y="['middle','middle','middle','bottom']"
                data-voffset="['240','240','240','100']"
                data-fontsize="['20','20','20','30']"
                data-lineheight="['60','60','60','60']"
                data-width="['275','275','275','400']"
                data-height="none"
                data-whitespace="nowrap"
                data-type="button"
                data-actions='[{"event":"click","action":"scrollbelow","offset":"","delay":"","speed":"1500","ease":"Power1.easeInOut"}]'
                data-responsive_offset="on"
                data-frames='[{"delay":900,"speed":2000,"frame":"0","from":"y:50px;opacity:0;fb:10px;fbr:100;","to":"o:1;fb:0;fbr:100;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;fbr:100;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"200","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;fb:0;fbr:110%;","style":"c:rgba(255,255,255,1);bs:solid;bw:0 0 0 0;"}]'
                data-textAlign="['center','center','center','center']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                href="https://www.youtube.com/watch?v=-vAzvs0Zdc4&ab_channel=AmyyWoahh"
                style={{
                  zIndex: 12,
                  minWidth: 300,
                  maxWidth: 300,
                  whiteSpace: 'nowrap',
                  fontSize: 20,
                  lineHeight: 60,
                  fontWeight: '600',
                  color: 'rgba(255, 255, 255, 1)',
                  fontFamily: 'Poppins',
                  backgroundColor: '#999',
                  borderColor: 'rgba(0, 0, 0, 1)',
                  borderRadius: '30px 30px 30px 30px',
                  outline: 'none',
                  boxShadow: 'none',
                  boxSizing: 'border-box',
                }}
              >
                We're fine, right?{' '}
                <i className="fa-solid  fa-external-link-alt"></i>
              </a>
            </li>
          </ul>
          <div
            className="tp-bannertimer tp-bottom"
            style={{ visibility: 'hidden' }}
          ></div>
        </div>
      </div>
    </section>
  );
}

import { useEffect, useState } from 'react';
import StepForwardIcon from '../../../../components/StepForwardIcon';
import { initializePioneerSlider } from './initialize';

interface Props {
  scrollToNext: (event: React.MouseEvent<any>) => void;
}
export default function PioneerSlider({ scrollToNext }: Props) {
  const [isAnimationActive, setIsAnimationActive] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsAnimationActive(true), 3000);
  }, []);

  useEffect(() => {
    initializePioneerSlider();
  }, []);

  return (
    <section
      id="slider"
      className="slider-element revslider-wrap include-header"
    >
      <div
        id="rev_slider_145_1_wrapper"
        className="rev_slider_wrapper fullwidthbanner-container"
        data-alias="parallax_header55"
        data-source="gallery"
        style={{
          margin: '0px auto',
          background: 'transparent',
          padding: '0px',
          marginTop: '0px',
          marginBottom: '0px',
        }}
      >
        <div
          id="rev_slider_145_1"
          className="rev_slider fullwidthabanner"
          style={{ display: 'none' }}
          data-version="5.4.8.1"
        >
          <ul>
            <li
              data-index="rs-365"
              data-transition="fade"
              data-slotamount="default"
              data-hideafterloop="0"
              data-hideslideonmobile="off"
              data-easein="default"
              data-easeout="default"
              data-masterspeed="300"
              data-rotate="0"
              data-saveperformance="off"
              data-title="Slide"
              data-param1=""
              data-param2=""
              data-param3=""
              data-param4=""
              data-param5=""
              data-param6=""
              data-param7=""
              data-param8=""
              data-param9=""
              data-param10=""
              data-description=""
            >
              <img
                src="include/rs-plugin/demos/assets/images/transparent.png"
                data-bgcolor="#afb2ab"
                style={{ background: '#afb2ab' }}
                alt="Image"
                data-bgposition="center top"
                data-bgfit="cover"
                data-bgrepeat="no-repeat"
                data-bgparallax="9"
                className="rev-slidebg"
                data-no-retina
              />
              <div
                className="tp-caption tp-resizeme rs-parallaxlevel-5"
                id="slide-365-layer-7"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['-200','-200','-200','-200']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="image"
                data-basealign="slide"
                data-responsive_offset="on"
                data-frames='[{"delay":100,"speed":1000,"frame":"0","from":"sX:1;sY:1;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{ zIndex: 5 }}
              >
                <img
                  src="include/rs-plugin/demos/assets/images/sky.jpg"
                  alt="Image"
                  data-ww="['1920px','1600px','1200px','1200px']"
                  data-hh="['1080px','900','675','675px']"
                  data-no-retina
                />
              </div>

              <div
                className="tp-caption tp-shape tp-shapewrapper tp-resizeme"
                id="slide-365-layer-14"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['0','0','0','0']"
                data-width="full"
                data-height="full"
                data-whitespace="nowrap"
                data-type="shape"
                data-basealign="slide"
                data-responsive_offset="on"
                data-frames='[{"delay":100,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: 6,
                  background:
                    'linear-gradient(180deg, rgba(175,178,171,0) 0%, rgba(175,178,171,1) 85%, rgba(175,178,171,1) 100%)',
                }}
              ></div>

              <div
                className="tp-caption tp-resizeme rs-parallaxlevel-2"
                id="slide-365-layer-20"
                data-x="['left','left','left','left']"
                data-hoffset="['604','474','324','164']"
                data-y="['top','top','top','top']"
                data-voffset="['164','94','174','264']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":400,"speed":3000,"frame":"0","from":"x:right;sX:1;sY:1;opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{ zIndex: 7 }}
              >
                <div
                  className="rs-looped rs-slideloop"
                  data-easing="Linear.easeNone"
                  data-speed="200"
                  data-xs="0"
                  data-xe="600"
                  data-ys="0"
                  data-ye="0"
                >
                  <img
                    src="include/rs-plugin/demos/assets/images/cloud-p1.png"
                    alt="Image"
                    data-ww="['700px','700px','500px','300px']"
                    data-hh="['400px','400px','286','171']"
                    data-no-retina
                  />
                </div>
              </div>

              <div
                className="tp-caption tp-resizeme rs-parallaxlevel-1"
                id="slide-365-layer-1"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','-210']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['50','50','50','116']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="image"
                data-basealign="slide"
                data-responsive_offset="on"
                data-frames='[{"delay":300,"speed":3000,"frame":"0","from":"y:150px;sX:1.5;sY:1.5;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{ zIndex: 8 }}
              >
                <img
                  src="include/rs-plugin/demos/assets/images/mountain.png"
                  alt="Image"
                  data-ww="['1920px','1600px','1300px','1300px']"
                  data-hh="['1080px','900','731px','731px']"
                  data-no-retina
                />
              </div>

              <div
                className="tp-caption tp-resizeme rs-parallaxlevel-2"
                id="slide-365-layer-18"
                data-x="['left','left','left','left']"
                data-hoffset="['-119','-119','-19','-39']"
                data-y="['top','top','top','top']"
                data-voffset="['244','124','184','214']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":500,"speed":3000,"frame":"0","from":"x:left;sX:1;sY:1;opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{ zIndex: 9 }}
              >
                <div
                  className="rs-looped rs-slideloop"
                  data-easing="Linear.easeNone"
                  data-speed="200"
                  data-xs="0"
                  data-xe="2000"
                  data-ys="0"
                  data-ye="0"
                >
                  <img
                    src="include/rs-plugin/demos/assets/images/cloud-p2.png"
                    alt="Image"
                    data-ww="['500px','500px','350px','200px']"
                    data-hh="['300px','300px','210','120px']"
                    data-no-retina
                  />
                </div>
              </div>

              <div
                className="tp-caption tp-resizeme rs-parallaxlevel-8"
                id="slide-365-layer-19"
                data-x="['left','left','left','left']"
                data-hoffset="['754','584','484','144']"
                data-y="['top','top','top','top']"
                data-voffset="['349','239','319','369']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="image"
                data-responsive_offset="on"
                data-frames='[{"delay":600,"speed":3000,"frame":"0","from":"x:right;sX:1;sY:1;opacity:0;","to":"o:1;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{ zIndex: 10 }}
              >
                <div
                  className="rs-looped rs-slideloop"
                  data-easing="Linear.easeNone"
                  data-speed="75"
                  data-xs="0"
                  data-xe="500"
                  data-ys="0"
                  data-ye="0"
                >
                  <img
                    src="include/rs-plugin/demos/assets/images/cloud-p3.png"
                    alt="Image"
                    data-ww="['600px','600px','400px','400px']"
                    data-hh="['300px','300px','200','200']"
                    data-no-retina
                  />
                </div>
              </div>

              <div
                className="tp-caption tp-resizeme"
                id="slide-365-layer-12"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['0','53','10','30']"
                data-fontsize="['20','20','30','17']"
                data-lineheight="['35','35','35','30']"
                data-width="['480','480','480','320']"
                data-height="none"
                data-whitespace="normal"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":800,"speed":2000,"frame":"0","from":"y:50px;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['center','center','center','center']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: 11,
                  minWidth: '480px',
                  maxWidth: '480px',
                  whiteSpace: 'normal',
                  fontSize: '20px',
                  lineHeight: '35px',
                  fontWeight: 600,
                  color: '#ffffff',
                  letterSpacing: '0px',
                  fontFamily: 'Poppins',
                }}
              >
                Join the young changemakers redefining how we overcome cravings,
                urges, and unwanted habits.
              </div>

              <a
                className="tp-caption rev-btn tp-resizeme"
                onClick={scrollToNext}
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['120','170','150','150']"
                data-fontsize="['20','20','20','15']"
                data-lineheight="['60','60','60','50']"
                data-width="['300','300','300','200']"
                data-height="none"
                data-whitespace="nowrap"
                data-type="button"
                data-responsive_offset="on"
                data-frames='[{"delay":900,"speed":2000,"frame":"0","from":"y:50px;opacity:0;fb:10px;fbr:100;","to":"o:1;fb:0;fbr:100;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;fbr:100;","ease":"Power3.easeInOut"},{"frame":"hover","speed":"200","ease":"Linear.easeNone","to":"o:1;rX:0;rY:0;rZ:0;z:0;fb:0;fbr:110%;","style":"c:rgba(255,255,255,1);bs:solid;bw:0 0 0 0;"}]'
                data-textAlign="['center','center','center','center']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: 12,
                  minWidth: '300px',
                  maxWidth: '300px',
                  whiteSpace: 'nowrap',
                  fontSize: '20px',
                  lineHeight: '60px',
                  fontWeight: 600,
                  color: 'rgba(255,255,255,1)',
                  fontFamily: 'Poppins',
                  backgroundColor: 'rgb(248,37,68)',
                  borderColor: 'rgba(0,0,0,1)',
                  borderRadius: '30px',
                  outline: 'none',
                  boxShadow: 'none',
                  boxSizing: 'border-box',
                  MozBoxSizing: 'border-box',
                  WebkitBoxSizing: 'border-box',
                  cursor: 'pointer',
                }}
              >
                Read more
              </a>

              <div
                className="tp-caption tp-no-events tp-resizeme rs-parallaxlevel-10"
                id="slide-365-layer-4"
                data-x="['left','left','left','left']"
                data-hoffset="['-150','-100','-140','-210']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['140','140','140','190']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="image"
                data-basealign="slide"
                data-responsive_offset="on"
                data-frames='[{"delay":300,"speed":2000,"frame":"0","from":"x:left;y:bottom;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{ zIndex: 13, pointerEvents: 'none' }}
              >
                <img
                  src="include/rs-plugin/demos/assets/images/guy-1.png"
                  alt="Image"
                  data-ww="['728px','500px','400px','400px']"
                  data-hh="['932px','640','512','512']"
                  data-no-retina
                />
              </div>
              <div
                className="tp-caption tp-resizeme rs-parallaxlevel-7"
                id="slide-365-layer-5"
                data-x="['center','center','center','center']"
                data-hoffset="['-180','-200','-200','0']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['-300','-300','-340','-350']"
                data-fontsize="['100','100','100','80']"
                data-lineheight="['150','100','90','60']"
                data-letterspacing="['-5','-3','-3','-2']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":700,"speed":2000,"frame":"0","from":"y:50px;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: 14,
                  whiteSpace: 'nowrap',
                  fontSize: '150px',
                  lineHeight: '150px',
                  fontWeight: 700,
                  color: '#ffffff',
                  letterSpacing: '-5px',
                  fontFamily: 'Poppins',
                  textShadow: '0px 15px 30px rgba(150, 150, 150, 0.25)',
                }}
              >
                calling
              </div>

              <div
                className="tp-caption tp-resizeme rs-parallaxlevel-7"
                id="slide-365-layer-6"
                data-x="['center','center','center','center']"
                data-hoffset="['-320','-280','-310','-190']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['-220', '-220','-240','-290']"
                data-fontsize="['65','60','50','40']"
                data-lineheight="['150','100','90','60']"
                data-letterspacing="['-2','-2','-2','-1']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":700,"speed":2000,"frame":"0","from":"y:50px;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: 14,
                  whiteSpace: 'nowrap',
                  fontSize: '150px',
                  lineHeight: '150px',
                  fontWeight: 700,
                  fontStyle: 'italic',
                  color: '#ffffff',
                  letterSpacing: '-2px',
                  fontFamily: 'Poppins',
                  textShadow: '0px 15px 30px rgba(150, 150, 150, 0.25)',
                }}
              >
                all
              </div>

              <div
                onMouseEnter={() => setIsAnimationActive(true)}
                onMouseLeave={() => setIsAnimationActive(false)}
                className="tp-caption rs-parallaxlevel-8"
                id="slide-365-layer-11"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['-190','-150','-220','-250']"
                data-fontsize="['130','150','130','90']"
                data-lineheight="['130','140','90','60']"
                data-letterspacing="['-5','-3','-3','-2']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":700,"speed":2000,"frame":"0","from":"y:50px;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: 14,
                  whiteSpace: 'nowrap',
                  fontSize: '150px',
                  lineHeight: '150px',
                  fontStyle: 'italic',
                  fontWeight: 700,
                  color: '#fafafa',
                  letterSpacing: '-5px',
                  fontFamily: 'Poppins',
                  textShadow: '0px 15px 30px rgba(150, 150, 150, 0.25)',
                }}
              >
                pioneers
              </div>

              <div
                className="tp-caption tp-resizeme rs-parallaxlevel-8"
                id="slide-365-layer-11"
                data-x="['center','center','center','center']"
                data-hoffset="['360','300','200','0']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['-192','-50','-120','-120']"
                data-fontsize="['150','100','90','120']"
                data-lineheight="['150','100','90','60']"
                data-letterspacing="['-5','-3','-3','-2']"
                data-width="none"
                data-height="none"
                data-whitespace="nowrap"
                data-type="text"
                data-responsive_offset="on"
                data-frames='[{"delay":700,"speed":2000,"frame":"0","from":"y:50px;opacity:0;fb:10px;","to":"o:1;fb:0;","ease":"Power4.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: 14,
                  whiteSpace: 'nowrap',
                  fontSize: '150px',
                  lineHeight: '150px',
                  fontStyle: 'italic',
                  fontWeight: 700,
                  color: '#ffffff',
                  letterSpacing: '-5px',
                  fontFamily: 'Poppins',
                  textShadow: '0px 15px 30px rgba(150, 150, 150, 0.25)',
                }}
              >
                <StepForwardIcon
                  color="#ffffff"
                  size={130}
                  isActive={isAnimationActive}
                />
              </div>

              <div
                className="tp-caption tp-no-events tp-shape tp-shapewrapper tp-resizeme rs-parallaxlevel-10"
                id="slide-365-layer-5"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['420','335','296','295']"
                data-width="full"
                data-height="['350','250','200','300']"
                data-whitespace="nowrap"
                data-type="shape"
                data-basealign="slide"
                data-responsive_offset="on"
                data-frames='[{"delay":100,"speed":2000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: 15,
                  background:
                    'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)',
                  pointerEvents: 'none',
                }}
              ></div>

              <div
                className="tp-caption tp-no-events tp-shape tp-shapewrapper tp-resizeme rs-parallaxlevel-10"
                id="slide-365-layer-9"
                data-x="['center','center','center','center']"
                data-hoffset="['0','0','0','0']"
                data-y="['middle','middle','middle','middle']"
                data-voffset="['845','710','646','695']"
                data-width="full"
                data-height="500"
                data-whitespace="nowrap"
                data-type="shape"
                data-basealign="slide"
                data-responsive_offset="on"
                data-frames='[{"delay":100,"speed":2000,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'
                data-textAlign="['inherit','inherit','inherit','inherit']"
                data-paddingtop="[0,0,0,0]"
                data-paddingright="[0,0,0,0]"
                data-paddingbottom="[0,0,0,0]"
                data-paddingleft="[0,0,0,0]"
                style={{
                  zIndex: 16,
                  backgroundColor: 'rgb(0,0,0)',
                  pointerEvents: 'none',
                }}
              ></div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

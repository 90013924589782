import {
  collection,
  DocumentSnapshot,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../firebase';

export function useTopCities() {
  // Get the top cities
  const [cities, setCities] = useState<DocumentSnapshot[]>([]);
  const q = query(
    collection(db, 'leaderboardCities'),
    orderBy('interestedParentsCount', 'desc'),
    limitToLast(5)
  );

  useEffect(() => {
    const unsubscribe = onSnapshot(q, ({ docs }) => {
      setCities(docs);
    });

    return unsubscribe;
  }, []);

  return cities;
}

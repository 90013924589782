import { animated, SpringRef, useSpring } from '@react-spring/web';
import Lottie, { LottieRef } from 'lottie-react';
import './Orb.scss';

interface Props {
  size: number;
  scaleSpringRef: SpringRef;
  hueSpringRef: SpringRef;
  saturationSpringRef: SpringRef;
  lottieRef: LottieRef;
}

export default function ZaraOrb({
  hueSpringRef,
  scaleSpringRef,
  saturationSpringRef,
  lottieRef,
  size,
}: Props) {
  const { scale } = useSpring({
    from: { scale: 1 },
    ref: scaleSpringRef,
  });

  const { hue } = useSpring({
    from: { hue: 0 },
    config: { tension: 10, friction: 100 },
    ref: hueSpringRef,
  });

  const { saturation } = useSpring({
    from: { saturation: 20 },
    config: { tension: 2, friction: 120, mass: 150 },
    ref: saturationSpringRef,
  });

  const outerStyle = {
    width: size,
    height: size,
    filter: saturation.to((s) => `grayscale(${100 - s}%)`),
  };

  const innerStyle = {
    transform: scale.to((s) => `scale(${s})`),
    filter: hue.to((h) => `hue-rotate(${h}deg)`), // Map "hue" to CSS filter
  };

  return (
    <animated.div style={outerStyle}>
      <animated.div style={innerStyle}>
        <Lottie
          lottieRef={lottieRef}
          animationData={require('../../assets/zara-orb.json')}
          loop={true}
          autoplay={true}
        />
      </animated.div>
    </animated.div>
  );
}

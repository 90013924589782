import { ReactNode } from 'react';
import './Quadrant.css';

interface Props {
  title: ReactNode;
  children: ReactNode;
  background?: ReactNode;
}

export default function QuadrantComponent({
  title,
  background,
  children,
}: Props) {
  return (
    <div className="quadrant-container">
      {background}
      <div className="quadrant-content">
        <div>{title}</div>
        <div style={{ color: 'white' }}>{children}</div>
      </div>
    </div>
  );
}

interface Props {
  scrollToNext: (event: React.MouseEvent<any>) => void;
}

export default function YouSection({ scrollToNext }: Props) {
  return (
    <section
      id="slider"
      className="slider-element min-vh-100 block-hero-8 py-0"
      style={{
        backgroundImage:
          'radial-gradient(circle at top right, #efe9e2 0%, #efe9e2 15%,transparent 15%, transparent 100%)',
      }}
    >
      <div className="">
        <div className="row min-vh-100 align-items-center justify-content-center">
          <div
            className="col-lg-6 min-vh-100"
            style={{
              background:
                "url('images/pioneers/pioneers.jpg') no-repeat center center / cover",
            }}
          ></div>

          <div className="col-lg-6 p-5 p-md-6">
            <h4 className="font-secondary mb-4 ls-1 h1">
              Calling all Pioneers
            </h4>
            <h4 className="mt-4 fw-light">
              We're connecting with 10 young people in 3 cities, in the Impulse
              Pioneer Program. We're looking for Pioneers who can help us take
              Impulse to the next level... Before we take it to the world.
            </h4>
            <div className="d-flex flex-row align-items-center">
              <a
                href="#"
                onClick={scrollToNext}
                className="button button-large text-white py-3 px-5 text-capitalize ls-0 shadow-sm ms-0 mt-2 me-4"
                style={{
                  backgroundImage: "url('images/blocks/preview/hero-8.jpg')",
                  backgroundSize: 'cover',
                }}
              >
                <i className="bi-arrow-down-circle"></i> See if I'm a good fit
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

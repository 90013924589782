import { MouseEventHandler } from 'react';

interface HeaderLinkItem {
  type: 'link';
  text: string;
  href: string;
}

interface HeaderButtonItem {
  type: 'button';
  text: string;
  onClick: MouseEventHandler;
}

export type HeaderItem = HeaderLinkItem | HeaderButtonItem;

interface Props {
  isSticky: boolean;
  items: HeaderItem[];
}

export default function Header({ isSticky, items }: Props) {
  return (
    <header
      id="header"
      className={`dark full-header transparent-header border-full-header header-size-custom ${
        isSticky ? 'sticky-header' : ''
      }`}
      data-sticky-shrink="false"
      data-sticky-offset="full"
      data-sticky-offset-negative="auto"
    >
      <div id="header-wrap">
        <div className="container">
          <div className="header-row">
            <div id="logo">
              <a href="/">
                <img
                  className="logo-default"
                  srcSet="images/logo.png, images/logo@2x.png 2x"
                  src="images/logo@2x.png"
                />
                <img
                  className="logo-dark"
                  srcSet="images/logo.png, images/logo@2x.png 2x"
                  src="images/logo@2x.png"
                />
              </a>
            </div>
            <div className="primary-menu-trigger">
              <button
                className="cnvs-hamburger"
                type="button"
                title="Open Mobile Menu"
              >
                <span className="cnvs-hamburger-box">
                  <span className="cnvs-hamburger-inner"></span>
                </span>
              </button>
            </div>
            <nav className="primary-menu">
              <ul
                className="one-page-menu menu-container"
                data-easing="easeInOutExpo"
                data-speed="1250"
                data-offset="65"
              >
                {items.map((item, index) => (
                  <li key={index} className="menu-item">
                    {item.type === 'link' ? (
                      <a href={item.href} className="menu-link">
                        <div>{item.text}</div>
                      </a>
                    ) : (
                      <button onClick={item.onClick} className="menu-link">
                        <div>{item.text}</div>
                      </button>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="header-wrap-clone"></div>
    </header>
  );
}

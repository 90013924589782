import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../../components/Footer';
import Header, { HeaderItem } from '../../components/Header';
import ImpulseSection from '../../components/SharedHomepageSection/ImpulseSection';
import { headerItems } from '../../utils/headerItems';
import { makeScrollToRefFn } from '../../utils/scrollTo';
import './Pioneers.css';
import ApplySection from './sections/Apply';
import AreYouAFitSection from './sections/AreYouAFit';
import CallingAllPioneersSection from './sections/CallingAllPioneers';
import HabitLoopSection from './sections/HabitLoop';
import PioneerSlider from './sections/Slider';
import WorldSection from './sections/World';

const description =
  "We're looking for pioneer teenagers to build Impulse to be the best that it can be";

export default function PioneersPage() {
  const refs = {
    impulse: useRef(null),
    impulseLoop: useRef(null),
    thePlan: useRef(null),
    apply: useRef(null),
  };

  const applyButton: HeaderItem = {
    type: 'button',
    text: 'Apply',
    onClick: () =>
      (refs.apply.current as any)?.scrollIntoView({ behavior: 'smooth' }),
  };

  useEffect(() => {
    (window as any).initCanvas();
    (window as any).SEMICOLON.DocumentOnReady.init();
  }, []);

  return (
    <>
      <Helmet>
        <title>Pioneers | Impulse</title>
        <meta name="description" content={description} />
        <meta property="og:title" content="Pioneers | Impulse" />
        <meta property="og:description" content={description} />
      </Helmet>
      <Header isSticky items={[headerItems.blog, applyButton]} />
      <PioneerSlider scrollToNext={makeScrollToRefFn(refs.impulseLoop)} />
      <HabitLoopSection sectionRef={refs.impulseLoop} />
      <ImpulseSection sectionRef={refs.impulse} />
      <CallingAllPioneersSection
        scrollToNext={makeScrollToRefFn(refs.thePlan)}
      />
      <AreYouAFitSection sectionRef={refs.thePlan} />
      <WorldSection scrollToNext={makeScrollToRefFn(refs.apply)} />
      <ApplySection sectionRef={refs.apply} />
      <Footer />
    </>
  );
}

import { animated, useSpring, useSpringRef } from '@react-spring/web';
import * as React from 'react';

type Props = React.SVGProps<SVGSVGElement> & {
  isActive: boolean;
  size: number;
  color: string;
};

export default function StepForwardIcon({
  stroke,
  size,
  color,
  isActive,
  ...rest
}: Props) {
  const pathLength = 108; // Adjust to match your path length
  const strokeDashArray = '4, 50';
  const ref = useSpringRef();

  const styles = useSpring({
    from: { strokeDashoffset: pathLength, strokeWidth: 0 },
    to: [
      { strokeDashoffset: pathLength, strokeWidth: 1 },
      { strokeDashoffset: 0, strokeWidth: 0 },
    ],
    config: { duration: 900 },
  });

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={'1em'}
      height={'1em'}
      viewBox="0 0 24 24"
      {...rest}
    >
      <animated.path
        fill="#fafafa"
        fillRule="evenodd"
        // stroke={stroke}
        stroke="#fafafa"
        strokeDasharray={strokeDashArray}
        {...styles}
        // strokeLinecap="round"
        d="M7 5h3v14H7V5m5 0 11 7-11 7V5M2 5h3v14H2V5Z"
      />
    </svg>
  );
}

// export default function StepForwardIcon(props: React.SVGProps<SVGSVGElement>) {
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
//       <path d="M7 5h3v14H7V5m5 0 11 7-11 7V5M2 5h3v14H2V5Z" />
//     </svg>
//   );
// }

import { useState } from 'react';

export default function ParentsSection() {
  const [isModalOpen, setModalOpen] = useState(false);
  return (
    <div className="row clearfix align-items-stretch">
      <div
        className="col-lg-6 col-padding"
        style={{ backgroundColor: 'white' }}
      >
        <div>
          <div className="heading-block border-bottom-0">
            <h3>Parent? Share your perspective with us!</h3>
          </div>
          <p className="lead">
            We're talking to parents to hear their perspectives on modern
            parenting. Chat with our team or Zara, our AI behavioral coach, to
            anonymously share your thoughts.
          </p>
          <a className="btn px-5 py-3 btn-dark rounded-0" href="/parents">
            Read more
          </a>
        </div>
      </div>
      <div
        className="col-lg-6 center col-padding"
        style={{
          background: "url('images/woman-smiling.png') center center no-repeat",
          backgroundSize: 'cover',
          minHeight: '415px',
        }}
      >
        <div>&nbsp;</div>
      </div>
    </div>
  );
}

import { useTopCities } from '../../../../utils/topCities';

export default function Leaderboard() {
  const cities = useTopCities();

  return (
    <section>
      <div className="container text-center mb-6">
        <h4>Leading cities</h4>
        <ol className="list-group list-group-numbered">
          {cities.map((city: any, index: number) => (
            <li
              className={`list-group-item ${index >= 3 ? 'opacity-75' : null}`}
              style={{ border: 'none' }}
              key={index}
            >
              {city.id}
            </li>
          ))}
        </ol>
      </div>
    </section>
  );
}

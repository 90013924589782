import axios from 'axios';
import AsyncSelect from 'react-select/async';

interface Props {
  afterChange: (selectedOption: string) => void;
}

export default function LocationAutocomplete({ afterChange }: Props) {
  const accessToken =
    'pk.eyJ1IjoibWljaGFlbC1pbXB1bHNlIiwiYSI6ImNtM3Q2dXhkajAzeTAycnB3eDZmNjV3MWIifQ.ocGtMe9JDYit5bN8O3QllA'; // Replace with your Mapbox token

  // Function to load options asynchronously
  const loadOptions = async (inputValue: string) => {
    if (!inputValue) return [];

    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          inputValue
        )}.json`,
        {
          params: {
            access_token: accessToken,
            autocomplete: true,
            types: 'place,country',
            limit: 10,
          },
        }
      );

      // Map the API response to the format react-select expects
      return response.data.features.map((feature: any) => ({
        value: feature.place_name,
        label: feature.place_name,
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={loadOptions}
      defaultOptions
      onChange={({ value }: any) => afterChange(value)}
      placeholder="E.g. Beverly Hills"
      classNames={{
        control: (state) => 'required' + (state.isFocused ? 'focused' : ''),
      }}
    />
  );
}

import Modal from '../Modal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function PrivacyModal({ isOpen, onClose }: Props) {
  return (
    <Modal title="Privacy policy" isOpen={isOpen} onClose={onClose}>
      <p>
        <em>Last Updated: Dec 9, 2024</em>
      </p>
      <p>
        Thank you for your interest in using Zara, an AI-driven behavioral
        coaching experience developed and provided by Impulse (“we,” “us,” or
        “our”). Your privacy and trust are important to us. This Privacy Policy
        explains how we collect, use, store, and share your information when you
        interact with Zara and the related services (“Services”).
      </p>

      <p>
        Please read this Privacy Policy carefully. By accessing or using the
        Services, you agree to the terms described herein.
      </p>

      <h4>1. Information We Collect</h4>

      <h5>a. Non-Personal Data</h5>
      <p>
        <strong>Behavioral Interaction Data:</strong> During your sessions with
        Zara (including voice and text inputs), we may process the content of
        your interactions, the questions asked, and any responses you provide.
      </p>
      <p>
        <strong>Usage Information:</strong> We collect information about how you
        use the Services, such as timestamps of your sessions, session duration,
        and which features or prompts you interact with.
      </p>
      <p>
        <strong>Device and Technical Data:</strong> We may collect general
        technical information about the device you use to access the Services,
        such as browser type, operating system, and approximate geographic
        region (e.g., country-level location). This information is collected in
        a way that does not directly identify you.
      </p>

      <h5>b. Personal Identifiers</h5>
      <p>
        We strive to design Zara so that it does not require personally
        identifiable information (PII) such as your name, email address, or
        other contact details. You have the choice to share limited personal
        details if you wish, but we strongly encourage you to use Zara
        anonymously. In general, the Services are intended to be used without
        providing PII.
      </p>

      <h5>c. Sensitive Information</h5>
      <p>
        Zara is designed to help you reflect on your own behaviors, triggers,
        and inner states. While this may include sensitive or personal subject
        matter (e.g., habits you wish to change), you are not required to
        disclose sensitive personal information. Any sensitive details you
        choose to share are provided voluntarily at your discretion.
      </p>

      <h4>2. How We Use Your Information</h4>

      <h5>a. To Provide and Improve the Services</h5>
      <p>
        We use the information you provide (including your interaction data) to
        operate, maintain, and improve Zara’s coaching features. For instance,
        Zara may use your responses to generate follow-up questions,
        suggestions, or insights, helping you gain clarity and
        self-understanding.
      </p>

      <h5>b. To Develop and Enhance User Experience</h5>
      <p>
        By analyzing non-personal and aggregated interaction patterns, we can
        refine Zara’s conversational models, improve response relevance, and
        enhance the overall user experience. This may include identifying common
        triggers, refining voice or text prompts, and making Zara more effective
        for diverse users.
      </p>

      <h5>c. To Conduct Research and Obtain Feedback</h5>
      <p>
        If you choose to submit your chat transcripts to us after reviewing
        them, we may use these transcripts for research, quality assurance,
        product development, and user experience improvement. The information
        you share helps us understand how Zara can better support
        individuals—including young people—in vulnerable moments.
      </p>

      <h4>3. Data Sharing and Disclosure</h4>

      <h5>a. Service Providers</h5>
      <p>
        We may share non-personal and aggregated data with third-party service
        providers who assist with technical operations such as hosting, data
        analysis, and AI model development. These providers are contractually
        obligated to use the data solely for our intended purposes and to
        maintain confidentiality.
      </p>

      <h5>b. Research and Development Partners</h5>
      <p>
        With your permission, we may share fully anonymized and aggregated
        transcripts or interaction summaries with researchers or strategic
        partners to improve the science and practice of behavioral coaching.
      </p>

      <h5>c. Legal Compliance and Safety</h5>
      <p>
        We may disclose information when required by law, regulation, legal
        process, or government request. We may also disclose information if we
        believe it is necessary to investigate potential violations of our Terms
        of Service, to detect or prevent fraud or security issues, or to protect
        the rights, property, or safety of our users or others.
      </p>

      <h4>4. Data Retention and Deletion</h4>

      <h5>a. Retention Period</h5>
      <p>
        Non-personal and aggregated data may be retained indefinitely for the
        purposes of improving Zara. Any transcripts you submit may also be
        stored for research and analysis, in an anonymized form whenever
        possible.
      </p>

      <h5>b. Deletion Requests</h5>
      <p>
        After you complete a session, you will have the opportunity to review
        your transcript. At that time, you may choose to delete it entirely. If
        you choose not to submit your transcript to us, the transcript will not
        be stored or used for any research purposes beyond your session.
      </p>

      <h5>c. Future Removal</h5>
      <p>
        If you later wish to have submitted transcripts removed from our
        servers, please contact us at [contact information]. We will make
        commercially reasonable efforts to remove your information, subject to
        any legal or compliance obligations.
      </p>

      <h4>5. Data Security</h4>
      <p>
        We implement reasonable administrative, technical, and physical
        safeguards to protect the data we hold against accidental or unlawful
        destruction, loss, alteration, unauthorized disclosure, or access. While
        we take these measures seriously, no online system can be 100% secure.
        We encourage you to take precautions, such as using secure networks and
        being mindful of the information you choose to share.
      </p>

      <h4>6. Children’s Privacy</h4>
      <p>
        The Services are intended to explore how Zara might support young people
        through the lens of feedback from parents and other adults. We do not
        knowingly solicit or collect personal information from children without
        parental consent. If you believe a child has provided us with personally
        identifiable information without your consent, please contact us at
        [contact information], and we will promptly address the issue.
      </p>

      <h4>7. International Data Transfer</h4>
      <p>
        Our Services may be provided from servers located in various
        jurisdictions. By using Zara, you consent to the transfer of information
        to and from servers and facilities located in your country and in other
        countries, where the data protection laws may differ from those of your
        home jurisdiction.
      </p>

      <h4>8. Changes to This Privacy Policy</h4>
      <p>
        We may update this Privacy Policy from time to time. Changes will be
        posted on this page and will take effect immediately upon posting. If
        the changes are significant, we may provide additional notice (e.g., by
        email or through the Services). We encourage you to review the Privacy
        Policy periodically to stay informed about our privacy practices.
      </p>

      <h4>9. Contact Us</h4>
      <p>
        If you have questions, concerns, or feedback about this Privacy Policy
        or our data practices, please contact us at:
      </p>
      <p>
        <strong>Email:</strong> hello@impulse.training
        <br />
        <strong>Postal Address:</strong> Brick City, 305 W Mill St 3, 3rd Floor,
        Springfield, MO 65806
      </p>

      <p>
        <strong>
          By using Zara and the Services, you acknowledge that you have read,
          understood, and agreed to this Privacy Policy.
        </strong>
      </p>
    </Modal>
  );
}

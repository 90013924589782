export default function TrackSection() {
  return (
    <section className="section m-0 py-0">
      <div style={{ position: 'relative' }}>
        <div className="container clearfix">
          <div className="heading-block mt-6 center">
            <h2>Calling all pioneers</h2>
            <span className="mx-auto mb-4">
              We're working with{' '}
              <span style={{ fontWeight: 'bold' }}>14-24 year old</span>{' '}
              pioneers across{' '}
              <span style={{ fontWeight: 'bold' }}>3 cities</span>, to take
              impulse to the next level before we take it to the world.
            </span>
            <a className="btn px-5 py-3 btn-dark rounded-0" href="/pioneers">
              Learn more
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

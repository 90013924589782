import { doc, onSnapshot } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase';

interface CallTrackerProps {
  callId: string;
}

const CallTracker: React.FC<CallTrackerProps> = ({ callId }) => {
  const [text, setText] = useState('Calling you now...');

  useEffect(() => {
    const callStatusDoc = doc(db, 'callStatus', callId);

    const unsubscribe = onSnapshot(callStatusDoc, (doc) => {
      const data = doc.data();
      if (!data) return;
      const callFinished = ['hanging-up', 'completed'].includes(data.status);
      if (!callFinished) return; // If not finished, no update
      setText(
        data.connectedAt
          ? `It was nice speaking with you`
          : `We couldn't reach you!`
      );
    });

    return () => unsubscribe();
  }, [callId]);

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <h1
          style={{
            fontFamily: `"Qwitcher Grypen", serif`,
            fontWeight: 400,
            fontStyle: 'normal',
            fontSize: '50px',
            color: 'white',
          }}
        >
          {text}
        </h1>
      </div>
    </div>
  );
};

export default CallTracker;

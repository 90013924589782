import WorldContentSection from '../components/WorldContentSection';

export default function Third() {
  return (
    <WorldContentSection
      title="Our mission: Overcoming"
      text="Our mission is to help people break free from habits and cycles that are holding them back. Your input could inspire a healthier, more balanced future for humanity."
    ></WorldContentSection>
  );
}

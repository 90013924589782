import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Button } from '../../../../components/button/Button';
import FourQuadrantsLoop from '../../../../components/FourQuadrantsLoop';
import './HabitLoop.css';
import { ActionSection } from './sections/ActionSection';
import ImpulseSection from './sections/ImpulseSection';
import RewardSection from './sections/RewardSection';
import TriggerSection from './sections/TriggerSection';

interface Props {
  sectionRef: React.RefObject<HTMLDivElement>;
}

export default function HabitLoopSection({ sectionRef }: Props) {
  const [initialQuadrant, setInitialQuadrant] = useState(0);
  const [activeQuadrant, setActiveQuadrant] = useState(0);
  const [startTime, setStartTime] = useState(performance.now());
  const { ref, inView } = useInView({ triggerOnce: false });

  const selectQuadrant = (quadrant: number) => {
    setInitialQuadrant(quadrant);
    setStartTime(performance.now());
  };

  const handleClick = (quadrant: number) => () => {
    selectQuadrant(quadrant);
    const targetId = quadrant < 2 ? 'buttons-container' : 'quadrant-loop';
    document.getElementById(targetId)?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (inView && activeQuadrant !== 0) selectQuadrant(0);
  }, [inView]);

  return (
    <div
      id="loop-section"
      ref={sectionRef}
      style={{ scrollMarginTop: '100px' }}
    >
      <div ref={ref} className="section mt-0 border-0 mb-0">
        <div className="container">
          <div className="heading-block text-center mb-0 buttons-container-heading-block">
            <h2>Do you know what it's like...</h2>
            <span>...To be stuck in a loop?</span>
            <div id="buttons-container" className="buttons-container">
              <Button
                buttonStyle={activeQuadrant === 0 ? 'action' : 'regular'}
                onClick={handleClick(0)}
                label="Trigger"
              />
              <i className="d-none d-sm-inline bi-arrow-right"></i>
              <Button
                buttonStyle={activeQuadrant === 1 ? 'action' : 'regular'}
                onClick={handleClick(1)}
                label="Impulse"
              />
              <i className="d-none d-sm-inline bi-arrow-right"></i>
              <Button
                buttonStyle={activeQuadrant === 2 ? 'action' : 'regular'}
                onClick={handleClick(2)}
                label="Action"
              />
              <i className="d-none d-sm-inline bi-arrow-right"></i>
              <Button
                buttonStyle={activeQuadrant === 3 ? 'action' : 'regular'}
                onClick={handleClick(3)}
                label="Reward"
              />
            </div>
          </div>
        </div>
      </div>
      <FourQuadrantsLoop
        First={TriggerSection}
        Second={ImpulseSection}
        Third={ActionSection}
        Fourth={RewardSection}
        initialQuadrant={initialQuadrant}
        setInitialQuadrant={setInitialQuadrant}
        activeQuadrant={activeQuadrant}
        setActiveQuadrant={setActiveQuadrant}
        startTime={startTime}
        setStartTime={setStartTime}
      />
    </div>
  );
}

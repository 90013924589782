import DottedMap from 'dotted-map/without-countries';
import { sample } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useTopCities } from '../../../../utils/topCities';
import cityCoordinates from './cityCoordinates';
import CountUpBlock from './components/CountUpBlock';
import First from './content/First';
import Second from './content/Second';
import Third from './content/Third';
import './WorldSection.css';

const getMapJSON = require('dotted-map').getMapJSON;
const mapJsonString = getMapJSON({ height: 100, grid: 'diagonal' });

const contentMap: Record<number, () => React.ReactElement> = {
  0: First,
  1: Second,
  2: Third,
};

interface Props {
  scrollToNext: (event: React.MouseEvent<any, MouseEvent>) => void;
}

export default function WorldSection({ scrollToNext }: Props) {
  const cities = useTopCities();
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.5,
    delay: 2000,
  });

  const [svgMap, setSvgMap] = useState('');
  const Component = contentMap[selectedSectionIndex];

  useEffect(() => {
    selectRandomCityLocations();
  }, []);

  function selectRandomCityLocations() {
    const map = new DottedMap({ map: JSON.parse(mapJsonString) });

    // Add 3 random points
    for (let i = 0; i < 3; i++) {
      const { lat, lng } = sample(cityCoordinates)!;
      map.addPin({
        lat,
        lng,
        svgOptions: { color: '#ff79d6', radius: 0.6 },
      });
    }

    setSvgMap(
      map.getSVG({
        radius: 0.25,
        color: '#423B38',
        shape: 'circle',
        backgroundColor: '#020300',
      })
    );
  }

  useEffect(() => {
    if (inView) {
      (window as any).SEMICOLON.Modules.counter();
    }
  }, [inView]);

  return (
    <div
      onClick={selectRandomCityLocations}
      ref={ref}
      className="dark"
      style={{ position: 'relative', height: '100vh' }}
    >
      <img
        className="map-img"
        src={`data:image/svg+xml;utf8,${encodeURIComponent(svgMap)}`}
      />
      <svg
        className="map-img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1200 800"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        <defs>
          <linearGradient id="mapGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop
              offset="0%"
              style={{ stopColor: 'rgba(255, 0, 150, 0.3)', stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: 'rgba(0, 150, 255, 0.3)', stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
        <rect x="0" y="0" width="1200" height="800" fill="url(#mapGradient)" />
      </svg>

      <div className="section m-0 p-0 world-section" style={{ height: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            paddingTop: 25,
            paddingBottom: 25,
            height: '100%',
          }}
        >
          <div className="container">
            <div className="row">
              <CountUpBlock
                isActive={selectedSectionIndex === 0}
                countFrom={150454}
                countTo={3}
                onClick={() => setSelectedSectionIndex(0)}
                text="Cities"
                className="offset-lg-3"
              />
              <CountUpBlock
                isActive={selectedSectionIndex === 1}
                countFrom={365}
                countTo={2}
                onClick={() => setSelectedSectionIndex(1)}
                text="Days"
              />
              <CountUpBlock
                isActive={selectedSectionIndex === 2}
                countFrom={1}
                countTo={1}
                onClick={() => setSelectedSectionIndex(2)}
                text="Mission"
              />
            </div>
          </div>

          <div className="container">
            <Component />
          </div>

          <div className="container">
            <div className="text-center">
              <a
                href="#apply-section"
                className="button button-3d button-rounded gradient-horizon"
              >
                Express your interest
                <img
                  style={{
                    width: 18,
                    height: 18,
                    marginBottom: 3,
                    marginLeft: 5,
                  }}
                  src="images/icons/step-forward-2.svg"
                  alt="Step Forward"
                />
              </a>
            </div>
          </div>
          <div className="ticker-wrap">
            <div className="ticker">
              <div className="ticker-item">
                <small>TOP CITIES</small>
              </div>
              {cities.map((city) => (
                <div className="ticker-item">{city.id}</div>
              ))}
              <div className="ticker-item">
                Don't see your city? Express your interest below!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { MouseEventHandler } from 'react';

interface Props {
  sectionRef: React.RefObject<HTMLDivElement>;
  scrollToNext: MouseEventHandler;
}

export default function BlockingSection({ sectionRef, scrollToNext }: Props) {
  // return (
  //   <div
  //     className="section parallax scroll-detect m-0 dark"
  //     style={{ padding: '150px 0' }}
  //   >
  //     <img src="images/alone.jpg" className="parallax-bg" />
  //     <div className="container">
  //       <div className="row justify-content-end">
  //         <div className="col-md-7">
  //           <div
  //             className="fslider testimonial testimonial-full text-center bg-transparent border-0 shadow-none p-0"
  //             data-arrows="false"
  //           >
  //             <div className="flexslider">
  //               <div className="slider-wrap">
  //                 <div className="slide">
  //                   <p className="mb-4 fs-4 fw-light ls-1">
  //                     Similique fugit repellendus expedita excepturi iure
  //                     perferendis provident quia eaque vero numquam?
  //                   </p>
  //                   <h4 className="h5 mb-0 fw-medium">Steve Jobs</h4>
  //                   <small className="text-muted">Apple Inc.</small>
  //                 </div>
  //                 <div className="slide">
  //                   <p className="mb-4 fs-4 fw-light ls-1">
  //                     Natus voluptatum enim quod necessitatibus quis expedita
  //                     harum provident eos obcaecati id culpa corporis molestias.
  //                   </p>
  //                   <h4 className="h5 mb-0 fw-medium">Collis Ta'eed</h4>
  //                   <small className="text-muted">Envato Inc.</small>
  //                 </div>
  //                 <div className="slide">
  //                   <p className="mb-4 fs-4 fw-light ls-1">
  //                     Incidunt deleniti blanditiis quas aperiam recusandae
  //                     consequatur ullam quibusdam cum libero illo rerum!
  //                   </p>
  //                   <h4 className="h5 mb-0 fw-medium">John Doe</h4>
  //                   <small className="text-muted">XYZ Inc.</small>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <div ref={sectionRef} className="section m-0 pt-6">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="mx-auto pt-6 " style={{ maxWidth: '900px' }}>
              <h3 className="text-stretch display-6 fw-bold position-relative">
                Block access to it all?
              </h3>
              <p className="lead mx-auto">
                Tools like Apple's Screen Time and Microsoft's Family Safety can
                block certain apps or limit screen time. While these tools can
                be helpful, they can transfer the responsibility of impulse
                control to an external system. When a child becomes more
                independent and these external constraints are no longer
                present, they will need to rely on their own ability to
                self-regulate.
              </p>
              <button
                onClick={scrollToNext}
                className="btn btn-lg btn-dark rounded-0 mx-0 px-4 mt-4"
              >
                Impulse's approach
              </button>
            </div>
          </div>
          <div className="col-md-5 offset-1 text-center d-flex align-items-center">
            <div>
              <img
                className="mt-6"
                src="images/alone.jpg"
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* // className="parallax-bg" /> */}
    </div>
  );
}

// <div id="section-about" class="text-center page-section" data-onepage-settings="{&quot;offset&quot;:70,&quot;speed&quot;:1250,&quot;easing&quot;:false}">

// <div class="container">

//   <h2 class="mx-auto mb-5 font-body" style="max-width: 700px; font-size: 40px;">A digital web studio creating stunning &amp; engaging online experiences</h2>

//   <p class="lead mx-auto mb-5" style="max-width: 800px;">Ford Foundation reduce child mortality fight against oppression refugee disruption pursue these aspirations effect. Free-speech Nelson Mandela change liberal; challenges of our times sustainability institutions.</p>

//   <p class="mb-5" style="font-size: 16px;"><a href="#" data-scrollto="#section-services" data-easing="easeInOutExpo" data-speed="1250" data-offset="70" class="more-link">Learn more <i class="uil uil-angle-right-b"></i></a></p>

//   <div class="clear"></div>

//   <div class="row mt-6 mx-auto" style="max-width: 1000px;">

//     <div class="col-md-4 mb-5">

//       <div class="team">
//         <div class="team-image">
//           <img src="one-page/images/team/1.jpg" alt="John Doe">
//           <div class="bg-overlay">
//             <div class="bg-overlay-content align-items-end not-animated" data-hover-animate="fadeIn" data-hover-speed="400" style="animation-duration: 400ms;">
//               <a href="#" class="social-icon bg-transparent h-bg-facebook" title="Facebook">
//                 <i class="fa-brands fa-facebook-f"></i>
//                 <i class="fa-brands fa-facebook-f"></i>
//               </a>
//               <a href="#" class="social-icon bg-transparent h-bg-x-twitter" title="Twitter">
//                 <i class="fa-brands fa-x-twitter"></i>
//                 <i class="fa-brands fa-x-twitter"></i>
//               </a>
//               <a href="#" class="social-icon bg-transparent h-bg-github" title="Github">
//                 <i class="fa-brands fa-github"></i>
//                 <i class="fa-brands fa-github"></i>
//               </a>
//             </div>
//             <div class="bg-overlay-bg not-animated" data-hover-animate="fadeIn" data-hover-speed="400" style="animation-duration: 400ms;"></div>
//           </div>
//         </div>
//         <div class="team-desc team-desc-bg">
//           <div class="team-title"><h4>John Doe</h4><span>CEO</span></div>
//         </div>
//       </div>
//     </div>
//     <div class="col-md-4 mb-5">
//       <div class="team">
//         <div class="team-image">
//           <img src="one-page/images/team/2.jpg" alt="Josh Clark">
//           <div class="bg-overlay">
//             <div class="bg-overlay-content align-items-end animated fadeOut" data-hover-animate="fadeIn" data-hover-speed="400" style="animation-duration: 400ms;">
//               <a href="#" class="social-icon bg-transparent h-bg-x-twitter" title="Twitter">
//                 <i class="fa-brands fa-x-twitter"></i>
//                 <i class="fa-brands fa-x-twitter"></i>
//               </a>
//               <a href="#" class="social-icon bg-transparent h-bg-linkedin" title="LinkedIn">
//                 <i class="fa-brands fa-linkedin"></i>
//                 <i class="fa-brands fa-linkedin"></i>
//               </a>
//               <a href="#" class="social-icon bg-transparent h-bg-flickr" title="Flickr">
//                 <i class="fa-brands fa-flickr"></i>
//                 <i class="fa-brands fa-flickr"></i>
//               </a>
//             </div>
//             <div class="bg-overlay-bg animated fadeOut" data-hover-animate="fadeIn" data-hover-speed="400" style="animation-duration: 400ms;"></div>
//           </div>
//         </div>
//         <div class="team-desc team-desc-bg">
//           <div class="team-title"><h4>Mary Jane</h4><span>Co-Founder</span></div>
//         </div>
//       </div>
//     </div>
//     <div class="col-md-4 mb-5">
//       <div class="team">
//         <div class="team-image">
//           <img src="one-page/images/team/3.jpg" alt="Mary Jane">
//           <div class="bg-overlay">
//             <div class="bg-overlay-content align-items-end not-animated" data-hover-animate="fadeIn" data-hover-speed="400" style="animation-duration: 400ms;">
//               <a href="#" class="social-icon bg-transparent h-bg-x-twitter" title="Twitter">
//                 <i class="fa-brands fa-x-twitter"></i>
//                 <i class="fa-brands fa-x-twitter"></i>
//               </a>
//               <a href="#" class="social-icon bg-transparent h-bg-vimeo" title="Vimeo">
//                 <i class="fa-brands fa-vimeo-v"></i>
//                 <i class="fa-brands fa-vimeo-v"></i>
//               </a>
//               <a href="#" class="social-icon bg-transparent h-bg-instagram" title="Instagram">
//                 <i class="bi-instagram"></i>
//                 <i class="bi-instagram"></i>
//               </a>
//             </div>
//             <div class="bg-overlay-bg not-animated" data-hover-animate="fadeIn" data-hover-speed="400" style="animation-duration: 400ms;"></div>
//           </div>
//         </div>
//         <div class="team-desc team-desc-bg">
//           <div class="team-title"><h4>Josh Clark</h4><span>Sales</span></div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
// </div>

import { useEffect } from 'react';
import PhoneDemo from '../../../../components/PhoneDemo';

export default function HomepageSlider() {
  const onVideoOverlayClick = (event: any) => {
    event.target.parentElement.querySelector('video').play();
  };

  useEffect(() => {
    document.body.classList.add('has-plugin-html5video');
  }, []);

  return (
    <section id="slider" className="slider-element min-vh-100 include-header">
      <div className="slider-inner">
        <div className="vertical-middle" style={{ paddingBottom: '300px' }}>
          <div className="container text-center">
            <div className="emphasis-title m-0">
              <h1 className="m-0">
                <span className="fw-bold font-body opm-large-word text-light">
                  overcome.
                </span>
              </h1>
            </div>
            <p
              className="fs-4 text-light"
              style={{
                maxWidth: '500px',
                marginRight: 'auto',
                marginLeft: 'auto',
              }}
            >
              Conquor Your Impulses with Personalized Support & Real-Time
              Guidance
            </p>
          </div>
        </div>
        <div className="video-wrap no-placeholder">
          <video
            poster="one-page/images/videos/3.jpg"
            preload="auto"
            loop
            autoPlay
            muted
            playsInline
          >
            {/* <source src="images/videos/ocean.webm" type="video/webm" /> */}
            <source src="images/videos/ocean.mp4" type="video/mp4" />
          </video>
          <div
            className="video-overlay"
            style={{ background: 'rgba(30, 30, 30, 0.9)' }}
          ></div>
        </div>
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          zIndex: 100,
        }}
      >
        <div className="container-large">
          <PhoneDemo />
        </div>
      </div>
    </section>
  );
}

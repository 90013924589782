import { MouseEventHandler } from 'react';

interface Props {
  scrollToImpulseSection: MouseEventHandler;
  scrollToNext: MouseEventHandler;
}

export default function TimelineSection({
  scrollToImpulseSection,
  scrollToNext,
}: Props) {
  return (
    <div className="section">
      <div className="container">
        <h2 className="text-center">
          <em>Here</em> is where our journeys meet
        </h2>
        <div className="row feature-box-border-vertical col-mb-50 justify-content-center align-items-center my-5">
          <div className="col-md-8 feature-box fbox-active fbox-dark fbox-sm">
            <div className="fbox-icon">
              <i className="fa-solid fa-check"></i>
            </div>
            <div className="fbox-content">
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <h3 className="mb-2" style={{ flexGrow: 1 }}>
                  Hardware exploration phase
                </h3>
                <h3 className="opacity-50">2023</h3>
              </div>
              <div className="masonry-thumbs grid-container row row-cols-3">
                <div
                  className="col"
                  data-lightbox="image"
                  title="Caption for Static Image LightBox"
                  data-zoom="true"
                >
                  <div className="grid-inner">
                    <img
                      className="lightbox-preview-image"
                      src="images/lightbox/1.png"
                    />
                    <div className="bg-overlay">
                      <div className="bg-overlay-content dark">
                        <i
                          className="uil uil-plus h4 mb-0"
                          data-hover-animate="fadeIn"
                        ></i>
                      </div>
                      <div
                        className="bg-overlay-bg dark"
                        data-hover-animate="fadeIn"
                      ></div>
                    </div>
                  </div>
                </div>
                <div
                  className="col"
                  data-lightbox="image"
                  title="Caption for Static Image LightBox"
                  data-zoom="true"
                >
                  <div className="grid-inner">
                    <img
                      className="lightbox-preview-image"
                      src="images/lightbox/2.png"
                    />
                    <div className="bg-overlay">
                      <div className="bg-overlay-content dark">
                        <i
                          className="uil uil-plus h4 mb-0"
                          data-hover-animate="fadeIn"
                        ></i>
                      </div>
                      <div
                        className="bg-overlay-bg dark"
                        data-hover-animate="fadeIn"
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="col" data-lightbox="image" data-zoom="true">
                  <div className="grid-inner">
                    <img
                      className="lightbox-preview-image"
                      src="images/lightbox/3.png"
                    />
                    <div className="bg-overlay">
                      <div className="bg-overlay-content dark">
                        <i
                          className="uil uil-plus h4 mb-0"
                          data-hover-animate="fadeIn"
                        ></i>
                      </div>
                      <div
                        className="bg-overlay-bg dark"
                        data-hover-animate="fadeIn"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                We explored three hardware systems to support people during
                impulse moments - a pendant, a ring, and a wrist-worn device. We
                were exploring "1 step" solutions that could be used in an
                impulse moment (a craving or urge) to help bring a person back
                to their pre-prepared "gameplan" for these moments.
              </p>
            </div>
          </div>

          <div className="clear"></div>

          <div className="col-md-8 feature-box fbox-active fbox-border fbox-light fbox-sm">
            <div className="fbox-icon">
              <i className="fa-solid fa-check border"></i>
            </div>
            <div className="fbox-content">
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <h3
                  className="text-transform-none mb-2"
                  style={{ flexGrow: 1 }}
                >
                  Gameplan development
                </h3>
                <h3 className="opacity-50">2024</h3>
              </div>
              <p>
                We shifted our focus to in-app experience after developing the{' '}
                <a href="#" onClick={scrollToImpulseSection}>
                  Impulse Lock Screen Widget
                </a>
                . This shift allowed us to make our tools to be accessible to
                users without the need for additional devices. We've been
                developing and testing in-app interventions such as breathing
                exercises, personalized voice messages from support people,
                Cognitive Behavioral Therapy (CBT) techniques and more - all
                activated with the tap of a button to empower users to "push
                through" the impulse moment and make a values-aligned decision.
              </p>
            </div>
          </div>

          <div className="clear"></div>

          <div className="col-md-8 feature-box fbox-active fbox-border fbox-light fbox-sm">
            <div className="fbox-icon">
              <i className="fa-solid fa-heart border"></i>
            </div>
            <div className="fbox-content">
              <h3 className="text-transform-none mb-2">
                We're connecting with parents of teenagers to better understand
                their concerns and perspectives.
              </h3>
              <p>This is where we'd love your help!</p>
              <button
                onClick={scrollToNext}
                className="btn btn-lg btn-dark rounded-0 mx-0 px-4 mt-4"
              >
                Share your perspective with us
              </button>
            </div>
          </div>

          <div className="clear"></div>

          <div className="col-md-8 feature-box fbox-border fbox-light fbox-sm">
            <div className="fbox-icon">
              <i className="fa-solid fa-check"></i>
            </div>
            <div className="fbox-content">
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <h3
                  className="text-transform-none mb-0"
                  style={{ flexGrow: 1 }}
                >
                  Impulse Pioneer Program
                </h3>
                <h3 className="opacity-50">Starting in January 2025</h3>
              </div>
              <p>
                A limited number of families from cities with the highest number
                of interested parents will be invited to participate in our
                Impulse Pioneer Program.
              </p>
            </div>
          </div>
          <div className="clear"></div>
          <div className="col-md-8 feature-box fbox-border fbox-light fbox-sm">
            <div className="fbox-icon">
              <i className="fa-solid fa-check"></i>
            </div>
            <div className="fbox-content">
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <h3
                  className="text-transform-none mb-0"
                  style={{ flexGrow: 1 }}
                >
                  Impulse private beta
                </h3>
                <h3 className="opacity-50">April 2025</h3>
              </div>
            </div>
          </div>
          <div className="clear"></div>
          <div className="col-md-8 feature-box pb-0 fbox-border fbox-light noborder fbox-sm">
            <div className="fbox-icon">
              <i className="fa-solid fa-check"></i>
            </div>
            <div className="fbox-content">
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <h3
                  style={{ flexGrow: 1 }}
                  className="text-transform-none mb-0"
                >
                  Impulse public launch
                </h3>
                <h3 className="opacity-50">April 2025</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

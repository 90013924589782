import { ReactNode } from 'react';
import ReactModal from 'react-modal';

interface Props {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  footer?: ReactNode;
  contentStyle?: React.CSSProperties;
}

export default function Modal({
  title,
  isOpen,
  onClose,
  children,
  footer,
  contentStyle,
}: Props) {
  const afterOpenModal = () => {
    document.querySelector('header')!.style.display = 'none';
    document.body.style.overflow = 'hidden'; // Disable scroll
  };

  const afterCloseModal = () => {
    document.querySelector('header')!.style.display = 'block';
    document.body.style.overflow = ''; // Re-enable scroll
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onAfterClose={afterCloseModal}
      onRequestClose={onClose}
      style={customStyles}
    >
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content" style={contentStyle}>
          <div className="modal-header" style={{ marginBottom: 10 }}>
            <h4 className="modal-title" style={{ marginRight: 20 }}>
              {title}
            </h4>
            <button
              type="button"
              className="btn-close btn-sm"
              onClick={onClose}
              aria-hidden="true"
            ></button>
          </div>
          <div className="modal-body">{children}</div>
          {footer}
        </div>
      </div>
    </ReactModal>
  );
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    maxWidth: '80%',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

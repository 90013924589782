import { Formik } from 'formik';
import { makeValidationFn } from 'impulse-core';
import { useEffect, useRef, useState } from 'react';
import Typed from 'typed.js';
import * as yup from 'yup';
import { phoneSchema } from '../../../../schemas/phone';
import ImpulseButton from '../ImpulseButton';
import './PhoneForm.css';

const schema = yup.object({ phoneNumber: phoneSchema });
const validate = makeValidationFn(schema);

export interface PhoneFormValues {
  phoneNumber: string;
}

interface Props {
  onSubmit: ({ phoneNumber }: PhoneFormValues) => void;
  isLoading?: boolean;
}

export default function PhoneForm({ isLoading, onSubmit }: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const typedRef = useRef<HTMLDivElement>(null);
  const initialValues: PhoneFormValues = { phoneNumber: '' };
  const [isSubmitButtonPressed, setSubmitButtonPressed] = useState(false);

  useEffect(() => {
    if (typedRef.current) {
      new Typed(typedRef.current, {
        strings: [
          "Hi, I’m Zara, your AI behavioral coach. I'm here to help with cravings or urges!",
        ],
        startDelay: 2000,
        typeSpeed: 10,
        showCursor: false,
      });
    }
  }, []);

  const handleImpulseButtonClick = () => inputRef.current?.focus();

  function Logger({ errors }: any) {
    console.log(errors);
    return null;
  }

  return (
    <>
      <ImpulseButton onPress={handleImpulseButtonClick} />
      <div className="phone_text my-2" ref={typedRef}></div>
      <Formik
        validate={validate}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ values, errors, handleChange, handleSubmit }) => (
          <div className="form-group show-error-msg">
            <div className="phone-form">
              <Logger errors={errors} />
              <input
                ref={inputRef}
                placeholder="Enter your phone number"
                type="tel"
                value={values.phoneNumber}
                onChange={handleChange('phoneNumber')}
                id="phone-field"
                disabled={isLoading}
                className="phone-input"
                onKeyUp={(e) => {
                  if (e.key === 'Enter') handleSubmit();
                }}
              />
              <button
                className="phone-form-button"
                onClick={() => {
                  setSubmitButtonPressed(true);
                  handleSubmit();
                }}
                disabled={isLoading}
              >
                Call me
              </button>
            </div>
            {isSubmitButtonPressed && errors.phoneNumber ? (
              <label
                className="m-0"
                style={{ fontSize: 12, color: '#FF4C4C' }}
                htmlFor="phone-field"
              >
                {errors.phoneNumber}
              </label>
            ) : null}
          </div>
        )}
      </Formik>
    </>
  );
}

import { InlineWidget } from 'react-calendly';
import ZaraInterview from '../../../../components/ZaraInterview';

interface Props {
  transcriptItems: { role: string; text: string }[];
  setTranscriptItems: (items: { role: string; text: string }[]) => void;
  openTranscriptModal: undefined | (() => void);
  sectionRef: React.MutableRefObject<HTMLDivElement | null>;
  isTranscriptSubmitted: boolean;
}

export default function InterviewSection({
  openTranscriptModal,
  setTranscriptItems,
  sectionRef,
  isTranscriptSubmitted,
}: Props) {
  const handleTalkToHumanButtonClick = () => {
    (window as any)
      .jQuery('.nav-tabs button[data-bs-target="#calendly"]')
      .tab('show');
  };

  return (
    <>
      <section ref={sectionRef} id="zara-interview" className="slider-element">
        <img
          src="images/blocks/preview/hero-16.svg"
          className="svg-bg d-none d-lg-block"
          alt=""
        />
        <div className="container p-6">
          <div className="row">
            <div className="col-md-6">
              <h1 className="display-6 fw-bold">
                Would you share your perspective with us?
              </h1>
              <p className="lead op-06">
                If you're a parent of a teen navigating the challenges of
                moderating behavior in today's digital age, we'd love to hear
                from you!
              </p>
              <p className="lead op-06">
                If your family has been finding success in setting and
                maintaining healthy limits and boundaries, we’d love to hear
                your story too.
              </p>
              <p className="lead op-06">
                Join the conversation on modern parenting by participating in an
                interview with our founding team or Zara, our AI behavioral
                coach.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="slider-element">
        <div className="vertical-middle min-vh-lg-100">
          <div className="container py-5 py-lg-0">
            <ul
              className="nav canvas-tabs tabs-bordered canvas-tabs tabs nav-tabs mb-3"
              id="canvas-tab-border"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="calendly-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#calendly"
                  type="button"
                  role="tab"
                  aria-selected="true"
                  tabIndex={-1}
                >
                  Connect with the Impulse founding team
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="canvas-zara-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#zara"
                  type="button"
                  role="tab"
                  aria-selected="false"
                >
                  Chat with Zara (AI)
                </button>
              </li>
            </ul>

            <div className="tab-content">
              <div
                className="tab-pane fade active show card border-0 rounded-4 shadow-lg connect-card vh-lg-75 py-4"
                id="calendly"
                role="tabpanel"
                aria-labelledby="calendly-tab"
                tabIndex={0}
              >
                <InlineWidget url="https://calendly.com/michael-impulse/30min" />
              </div>
              <div
                className="tab-pane fade card border-0 rounded-4 shadow-lg connect-card vh-lg-75 py-4"
                id="zara"
                role="tabpanel"
                aria-labelledby="canvas-zara-tab"
                tabIndex={0}
              >
                <ZaraInterview
                  openTranscriptModal={openTranscriptModal}
                  onCancelButtonClick={handleTalkToHumanButtonClick}
                  setTranscriptItems={setTranscriptItems}
                  isTranscriptSubmitted={isTranscriptSubmitted}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import { addDoc, collection } from 'firebase/firestore';
import { useState } from 'react';
import { db } from '../../firebase';
import LocationAutocomplete from '../LocationAutocomplete';

interface Props {
  setIsSubscribed: (isSubscribed: boolean) => void;
}

export default function SubscribeForm({ setIsSubscribed }: Props) {
  const [email, setEmail] = useState('');
  const [city, setCity] = useState('');

  const handleSubmitButtonClick = () => {
    const docsCollection = collection(db, 'interestedParents');
    addDoc(docsCollection, {
      email,
      city,
    }).then(() => {
      setEmail('');
      setCity('');
      setIsSubscribed(true);
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <LocationAutocomplete afterChange={setCity} />
      <p>
        Please provide contact details. They will not be associated with your
        interview transcript, which will remain anonymous.
      </p>

      <input
        type="text"
        placeholder="Email or phone number"
        style={{ marginRight: 20, flex: 1 }}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <div style={{ flex: 1 }}></div>

      <button
        onClick={handleSubmitButtonClick}
        className="btn btn-lg btn-dark rounded-0 mx-0 px-4 mt-4"
      >
        Submit
      </button>
    </div>
  );
}

import { HeaderItem } from '../components/Header';

export const headerItems: Record<string, HeaderItem> = {
  parents: {
    type: 'link',
    text: 'Parents',
    href: '/parents',
  },
  pioneers: {
    type: 'link',
    text: 'Teens',
    href: '/pioneers',
  },
  blog: {
    type: 'link',
    text: 'Blog',
    href: 'https://blog.impulse.training',
  },
};

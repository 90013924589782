import { Route, Routes } from 'react-router';
import HomePage from './pages/Home';
import ParentsPage from './pages/Parents';
import PioneersPage from './pages/Pioneers';

export default function App() {
  return (
    <Routes>
      <Route index element={<HomePage />} />
      <Route path="parents" element={<ParentsPage />} />
      <Route path="pioneers" element={<PioneersPage />} />
    </Routes>
  );
}

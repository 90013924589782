import { useEffect, useRef } from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import ImpulseSection from '../../components/SharedHomepageSection/ImpulseSection';
import { headerItems } from '../../utils/headerItems';
import GameplanSection from './sections/Gameplan';
import WaitlistSection from './sections/Parents';
import HomepageSlider from './sections/Slider';
import TrackSection from './sections/Track';

export default function HomePage() {
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    (window as any).initCanvas();
    (window as any).SEMICOLON.DocumentOnReady.init();
  }, []);

  return (
    <div>
      <Header
        isSticky
        items={[headerItems.parents, headerItems.pioneers, headerItems.blog]}
      />
      <HomepageSlider />
      <ImpulseSection sectionRef={sectionRef} />
      <GameplanSection />
      <TrackSection />
      <WaitlistSection />
      <Footer />
    </div>
  );
}

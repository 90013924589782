import { VideoSection } from '../components/VideoSection';

interface Props {
  isActive: boolean;
  onPauseParentLoop: () => void;
}

const videoSources = [
  { title: 'Sadness', src: 'images/impulse-loop/triggers/sadness.mp4' },
  { title: 'Anxiety', src: 'images/impulse-loop/triggers/anxiety.mp4' },
  { title: 'Boredom', src: 'images/impulse-loop/triggers/boredom.mp4' },
  { title: 'Frustration', src: 'images/impulse-loop/triggers/frustration.mp4' },
  { title: 'Loneliness', src: 'images/impulse-loop/triggers/loneliness.mp4' },
];

export default function TriggerSection({ isActive, onPauseParentLoop }: Props) {
  return (
    <VideoSection
      onPauseParentLoop={onPauseParentLoop}
      videoSources={videoSources}
      title={<h3 className="text-light">Trigger</h3>}
      isActive={isActive}
    >
      Every impulse loop has a starting point: a Trigger. It could be stress,
      boredom, a particular environment, or even a specific time of day.
    </VideoSection>
  );
}

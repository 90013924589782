interface Props {
  onPress: () => void;
}

export default function ImpulseButton({ onPress, ...rest }: Props) {
  return (
    <button onClick={onPress} {...rest}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-10 -37 80 69"
        style={{ width: '100px', height: '100px', position: 'relative' }}
      >
        <defs>
          <linearGradient id="grad" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop
              offset="0%"
              style={{ stopColor: 'white', stopOpacity: 0.1 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: 'white', stopOpacity: 0.4 }}
            />
          </linearGradient>
        </defs>
        <circle
          cx="30"
          cy="-2"
          r="32"
          fill="url(#grad)"
          stroke="white"
          strokeWidth="2"
        />
        <path
          stroke="white"
          fill="none"
          strokeWidth="2"
          d="M0 0 c 7.3786 -0.04 9.1526 -0.12 9.6365 -0.36 c 0.7258 -0.38 2.6611 -2.9 2.6611 -3.5 c 0 -0.24 0.2419 -0.54 0.5242 -0.66 c 0.4435 -0.18 0.6854 0.02 1.2499 1.02 c 1.9757 3.46 2.3386 3.96 3.145 4.34 c 1.2499 0.58 2.5402 0.5 3.5482 -0.18 c 0.6854 -0.5 0.9677 -1.26 1.6128 -4.36 l 0.9677 -5.46 l 0.8467 -5.9 l 0.9677 -6.9 c 0.3629 -2.44 0.6854 -3.8 1.008 -3.94 c 0.3226 -0.16 1.0886 5.94 1.4515 11.64 c 1.008 15.06 2.4192 25.24 3.7498 27.26 c 0.5242 0.82 1.8144 1.08 2.6611 0.56 c 0.8064 -0.48 1.6128 -3.54 2.2982 -8.52 c 0.3226 -2.58 0.7661 -5.28 0.9677 -6 l 0.4032 -1.46 c 0 -0.48 0.7661 0.18 1.008 0.86 c 0.4435 1.34 2.0563 1.82 3.2256 0.94 c 0.5645 -0.44 1.8547 -3.54 2.4192 -5.94 c 0.4032 -1.62 1.8144 -4.6 2.2176 -4.6 c 0.5242 0 1.4515 1.78 2.8627 5.4 c 1.8144 4.7 2.8627 6.62 3.7094 6.88 c 1.0483 0.34 1.9354 -0.06 2.9434 -1.3 c 1.129 -1.06 1.129 -0.06 5.161 -0.06"
        />
      </svg>
    </button>
  );
}

import { useState } from 'react';
import CallTracker from './components/CallTracker';
import PhoneBg from './components/PhoneBg';
import { default as PhoneForm, PhoneFormValues } from './components/PhoneForm';

const endpoint =
  'https://us-central1-impulse-tmp.cloudfunctions.net/api/unauthenticated/makeDemoCall';

export default function PhoneDemo() {
  const [isLoading, setIsLoading] = useState(false);
  const [callId, setCallId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const onSubmitPhoneForm = ({ phoneNumber }: PhoneFormValues) => {
    setIsLoading(true);
    fetch(endpoint, {
      method: 'POST',
      body: JSON.stringify({ phoneNumber }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === 'error') {
          throw new Error(data.errors.join(', '));
        }
        setCallId(data.call_id);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div
      className="phone_wrap"
      style={{
        justifyContent: 'center',
        paddingLeft: '0.84375rem',
        paddingRight: '0.84375rem',
        display: 'flex',
        overflow: 'hidden',
        minHeight: '380px',
      }}
    >
      <style>{`
        .phone_bg-combined {
          z-index: 0;
          grid-column-gap: 0px;
          grid-row-gap: 0px;
          grid-template-rows: auto auto;
          grid-template-columns: 9fr 23fr 11fr;
          grid-auto-columns: 1fr;
          width: 100%;
          min-height: 100%;
          display: grid;
          position: absolute;
          inset: 0% 0% auto;
        }
        .phone_phone-wrap {
          flex-flow: column;
          justify-content: flex-start;
          width: 100%;
          max-width: 32rem;
          padding: 4rem 4rem 0;
          display: flex;
          position: relative;
        }
        .container-large {
          width: 100%;
          max-width: 102.5rem;
          margin-left: auto;
          margin-right: auto;
        }
        .phone_bg-image {
          width: 100%;
          height: 100%;
        }
      `}</style>
      <div
        className="phone_phone-wrap"
        style={{
          position: 'relative',
          width: '100%',
          maxWidth: '32rem',
          padding: '4rem 4rem 0',
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <PhoneBg />
        <div
          style={{
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <style>{`
            .phone_text-wrap {
              z-index: 1;
              background-color: var(--background-color--background-primary);
              color: #fff;
              border: 1px solid #7a7a7a;
              font-size: 1.25rem;
              position: relative;
              box-shadow: 0 .1875rem .375rem #0000009e;
            }
            .phone_text {
              padding: 10px;
              font-size: 1.1rem;
              position: relative;
              border-radius: 6px;
              z-index: 100;
              min-height: 80px;
              color: white;
              background: #444;
            }
            button {
              background: transparent;
              border: none;
              opacity: 1;
              transition: opacity 0.2s ease-in-out;
              cursor: pointer;
            }
          `}</style>
          {callId ? (
            <CallTracker callId={callId} />
          ) : (
            <PhoneForm onSubmit={onSubmitPhoneForm} isLoading={isLoading} />
          )}
        </div>
        {isLoading && (
          <div
            className="loader"
            style={{
              display: 'flex',
              transition: '300ms opacity',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 100,
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <img src="images/spinner.svg" alt="Loading..." />
          </div>
        )}
      </div>
    </div>
  );
}
